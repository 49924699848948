import { ThemeMode } from 'commonConstants'
import { atom } from 'recoil'

const isMenuOpenState = atom<boolean>({
  key: 'isMenuOpenState',
  default: true,
})

const isMobileViewState = atom<boolean>({
  key: 'isMobileViewState',
  default: false,
})

const themeModeState = atom<ThemeMode>({
  key: 'themeModeState',
  default: ThemeMode.LIGHT,
})

const screenSizeState = atom<{ width: number; height: number }>({
  key: 'screenSizeState',
  default: {
    width: 0,
    height: 0,
  },
})

export { isMenuOpenState, isMobileViewState, themeModeState, screenSizeState }
