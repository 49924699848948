import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useIntl } from 'react-intl'
import useSWR from 'swr'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import { useForm, type SubmitHandler, Controller } from 'react-hook-form'
import { useParams } from 'react-router-dom'
import { useSnackbar } from 'notistack'
import LoadingButton from '@mui/lab/LoadingButton'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Stack from '@mui/material/Stack'
import FormControl from '@mui/material/FormControl'
import TextField from '@mui/material/TextField'
import InputAdornment from '@mui/material/InputAdornment'
import FormHelperText from '@mui/material/FormHelperText'
import InputLabel from '@mui/material/InputLabel'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import OutlinedInput from '@mui/material/OutlinedInput'
import CircularProgress from '@mui/material/CircularProgress'
import Chip from '@mui/material/Chip'
import Checkbox from '@mui/material/Checkbox'
import ListItemText from '@mui/material/ListItemText'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import LanguageIcon from '@mui/icons-material/Language'
import EditIcon from '@mui/icons-material/Edit'
import CottageIcon from '@mui/icons-material/Cottage'

import {
  MainHeader,
  BackButtonGrey,
  SettingWrapper,
} from 'components/StyledComponents'
import useRoute from 'hooks/useNavigate'
import { type Locale } from 'types'
import { Path } from '../commonConstants'
import {
  portalSettingState,
  sortedSupportLanguagesSelector,
} from 'state/portalSettingStates'
import MultiLineInput from 'components/form/MultiLineInput'
import { hasUnsavedChangesState } from 'state/formStates'
import TranslationDialog from 'components/TranslationDialog'
import useApi from 'hooks/useApi'

import { type ItemBasic } from 'components/item/itemTypes'
import usePortalSetting from 'hooks/usePortalSetting'
import {
  convertLocalizedStringToData,
  convertDataToLocalizedString,
} from 'utils/stringUtils'
import {
  type CommentTemplate,
  type CommentTemplateFormData,
} from 'components/commentTemplate/commentTemplateTypes'
import {
  TEMPLATE_MAX_LENGTH,
  TEMPLATE_NAME_MAX_LENGTH,
} from 'components/commentTemplate/commentTemplateConstants'

const CommentTemplateEditPage: React.FC = () => {
  const { formatMessage } = useIntl()
  const { templateId } = useParams()
  const { goTo } = useRoute()
  const supportLanguages = useRecoilValue(sortedSupportLanguagesSelector)
  const { sendPostRequest, sendPutRequest } = useApi()
  const { enqueueSnackbar } = useSnackbar()
  const [isSaving, setIsSaving] = useState(false)
  const portalSetting = useRecoilValue(portalSettingState)
  const [isTranslationDialogOpen, setIsTranslationDialogOpen] = useState(false)
  const [translationDialogTitle, setTranslationDialogTitle] = useState('')
  const [translationInputRows, setTranslationInputRows] = useState(3)
  const setHasUnsavedChanges = useSetRecoilState(hasUnsavedChangesState)
  const translationContext = useRef<'names' | 'contents' | null>(null)
  const [maxTranslationLength, setMaxTranslationLength] = useState(
    TEMPLATE_NAME_MAX_LENGTH,
  )
  const [items, setItems] = useState<ItemBasic[]>([])
  const [isLoadingItems, setIsLoadingItems] = useState(false)
  const { getLocalizedContent } = usePortalSetting()
  const { data: templateData, isLoading } = useSWR<CommentTemplate>(
    portalSetting && templateId
      ? `${process.env.REACT_APP_API_PATH ?? ''}/portals/${
          portalSetting.id
        }/templates/comments/${templateId}`
      : null,
  )
  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors, isValid, isDirty, dirtyFields },
    getValues,
    trigger,
    watch,
  } = useForm<CommentTemplateFormData>({
    mode: 'onTouched',
  })

  const names = watch('names')

  useEffect(() => {
    if (templateData) {
      setValue('names', convertLocalizedStringToData(templateData.names))
      setValue('contents', convertLocalizedStringToData(templateData.contents))
      setValue(
        'itemIds',
        templateData.items.map((item) => item.id),
      )
    }
  }, [templateData])

  const getItems = async (): Promise<void> => {
    setIsLoadingItems(true)
    const response = await sendPostRequest(
      `${process.env.REACT_APP_API_PATH ?? ''}/portals/${
        portalSetting?.id
      }/templates/comments:listAssignableItems`,
    )
    const itemsData = await response.json()
    setItems(itemsData)
    setIsLoadingItems(false)
  }

  useEffect(() => {
    if (portalSetting) {
      void getItems()
    }
  }, [portalSetting])

  useEffect(() => {
    setHasUnsavedChanges(isDirty)
  }, [isDirty])

  const handleGoBack = (): void => {
    goTo(Path.COMMENT_TEMPLATES_LIST)
  }

  const handleOpenNameTranslationDialog = (): void => {
    setIsTranslationDialogOpen(true)
    translationContext.current = 'names'
    setMaxTranslationLength(TEMPLATE_NAME_MAX_LENGTH)
    setTranslationDialogTitle(
      formatMessage({
        id: 'portal_item_edit.button.manage_localization',
      }),
    )
    setTranslationInputRows(1)
  }

  const handleOpenTemplateTranslationDialog = (): void => {
    setIsTranslationDialogOpen(true)
    translationContext.current = 'contents'
    setMaxTranslationLength(TEMPLATE_MAX_LENGTH)
    setTranslationDialogTitle(
      formatMessage({
        id: 'portal_item_edit.button.manage_localization',
      }),
    )
    setTranslationInputRows(3)
  }

  const handleSaveTranslation = (data: Record<Locale, string>): void => {
    if (translationContext.current) {
      setValue(translationContext.current, data, {
        shouldValidate: true,
        shouldTouch: true,
        shouldDirty: true,
      })
      void trigger(translationContext.current)
    }
    setIsTranslationDialogOpen(false)
  }

  const handleCloseTranslationDialog = (): void => {
    setIsTranslationDialogOpen(false)
  }

  const getTranslationDefaultLanguageValue = useCallback((): Record<
    string,
    string
  > => {
    if (translationContext.current) {
      return getValues(translationContext.current)
    }

    return {}
  }, [translationContext.current])

  const validateTranslations = (name: 'names' | 'contents'): null | string => {
    if (!portalSetting) {
      return null
    }

    const data = getValues(name)

    let missingTranslations: Locale[] = [...portalSetting.supportedLanguages]

    if (data) {
      const keys = Object.keys(data)
      keys.forEach((key) => {
        if (data[key]) {
          missingTranslations = missingTranslations.filter(
            (item) => item !== key,
          )
        }
      })

      if (missingTranslations.length) {
        return missingTranslations.join(', ')
      }
    }

    return null
  }

  const missingNamesTranslation = useMemo(
    () => !!dirtyFields.names && !!validateTranslations('names'),
    [names],
  )

  const shouldDisableSaving = useMemo(
    () =>
      !isValid ||
      Object.keys(errors).length > 0 ||
      isLoadingItems ||
      isSaving ||
      missingNamesTranslation,
    [
      isValid,
      errors,
      isLoadingItems,
      isSaving,
      portalSetting?.type,
      missingNamesTranslation,
    ],
  )

  const onSubmit: SubmitHandler<CommentTemplateFormData> = useCallback(
    async (data): Promise<void> => {
      if (portalSetting) {
        try {
          setIsSaving(true)
          const formData = {
            itemIds: data.itemIds,
            names: convertDataToLocalizedString(data.names),
            contents: convertDataToLocalizedString(data.contents),
          }

          if (templateId) {
            await sendPutRequest(
              `${process.env.REACT_APP_API_PATH ?? ''}/portals/${
                portalSetting.id
              }/templates/comments/${templateId}`,
              formData,
            )
          } else {
            await sendPostRequest(
              `${process.env.REACT_APP_API_PATH ?? ''}/portals/${
                portalSetting.id
              }/templates/comments`,
              formData,
            )
          }

          enqueueSnackbar(formatMessage({ id: 'general.text.changes_saved' }), {
            variant: 'success',
          })

          handleGoBack()
        } catch (error) {
          console.error(error)
        } finally {
          setIsSaving(false)
          setHasUnsavedChanges(false)
        }
      }
    },
    [portalSetting, templateId],
  )

  if ((templateId && isLoading) || !portalSetting) {
    return <CircularProgress />
  }

  return (
    <>
      {/* eslint-disable-next-line @typescript-eslint/no-misused-promises */}
      <form onSubmit={handleSubmit(onSubmit)} style={{ height: '100%' }}>
        <Stack height={'100%'} width={'100%'}>
          <Stack direction="row" width="100%" spacing={2} marginBottom={2}>
            <Stack flexGrow={1} direction="row" spacing={1}>
              <BackButtonGrey
                onClick={handleGoBack}
                size="small"
                aria-label={formatMessage({
                  id: 'general.icon_button.go_back',
                })}
              >
                <ArrowBackIcon />
              </BackButtonGrey>

              <MainHeader>
                {templateId &&
                  formatMessage({ id: 'comment_template_edit.header' })}
                {!templateId &&
                  formatMessage({ id: 'comment_template_add.header' })}
              </MainHeader>
            </Stack>

            {(!templateId || templateData?._operations.canEdit) && (
              <LoadingButton
                variant="contained"
                size="small"
                type="submit"
                disabled={shouldDisableSaving}
                loading={isSaving}
              >
                {formatMessage({ id: 'general.button.save' })}
              </LoadingButton>
            )}
          </Stack>

          <SettingWrapper width="100%" flexGrow={1}>
            <Stack paddingY={2} paddingX={4} spacing={2}>
              <FormControl fullWidth error={!!errors.itemIds}>
                <InputLabel id="items-label" size="small" required>
                  {formatMessage({
                    id: 'comment_template_edit.label.items',
                  })}
                </InputLabel>
                <Controller
                  name="itemIds"
                  control={control}
                  rules={{
                    required: true,
                  }}
                  defaultValue={[]}
                  render={({ field }) => (
                    <Select
                      {...field}
                      required
                      multiple
                      labelId="items-label"
                      label={formatMessage({
                        id: 'comment_template_edit.label.items',
                      })}
                      startAdornment={
                        <InputAdornment position="start">
                          <CottageIcon fontSize="small" color="primary" />
                        </InputAdornment>
                      }
                      input={
                        <OutlinedInput
                          label={formatMessage({
                            id: 'comment_template_edit.label.items',
                          })}
                        />
                      }
                      size="small"
                      fullWidth
                      variant="outlined"
                      renderValue={(selected) => (
                        <Box
                          sx={{
                            display: 'flex',
                            flexWrap: 'wrap',
                            gap: 0.5,
                          }}
                        >
                          {selected.map((value) => {
                            const selectedItem = items?.find(
                              (item) => item.id === value,
                            )

                            return (
                              <Chip
                                key={value}
                                size="small"
                                label={getLocalizedContent(selectedItem?.names)}
                              />
                            )
                          })}
                        </Box>
                      )}
                    >
                      {items?.map((item) => (
                        <MenuItem key={item.id} value={item.id}>
                          <Checkbox
                            checked={getValues('itemIds')?.includes(item.id)}
                          />
                          <ListItemText
                            primary={getLocalizedContent(item.names)}
                          />
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                />
              </FormControl>

              <Stack width="100%">
                {supportLanguages.length > 1 && (
                  <Stack direction="row" alignItems="center">
                    <Box flexGrow={1}>
                      <FormHelperText error>
                        {!!dirtyFields.names &&
                          !!validateTranslations('names') &&
                          formatMessage(
                            {
                              id: 'portal_item_edit.error.missing_translations',
                            },
                            {
                              missingTranslations:
                                validateTranslations('names'),
                            },
                          )}
                      </FormHelperText>
                    </Box>
                    <Button
                      size="small"
                      startIcon={<EditIcon />}
                      onClick={handleOpenNameTranslationDialog}
                    >
                      {formatMessage({
                        id: 'portal_item_edit.button.manage_localization',
                      })}
                    </Button>
                  </Stack>
                )}

                <FormControl>
                  <Controller
                    name={`names.${portalSetting.defaultLanguage}`}
                    control={control}
                    defaultValue=""
                    rules={{
                      maxLength: TEMPLATE_NAME_MAX_LENGTH,
                      required: true,
                    }}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        required
                        error={!!errors.names}
                        size="small"
                        label={`${formatMessage({
                          id: 'comment_template_edit.label.name',
                        })}${
                          supportLanguages.length < 2
                            ? ''
                            : ` (${portalSetting.defaultLanguage})`
                        }`}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <LanguageIcon fontSize="small" color="primary" />
                            </InputAdornment>
                          ),
                        }}
                        variant="outlined"
                        fullWidth
                      />
                    )}
                  />
                </FormControl>
              </Stack>

              <Box>
                {supportLanguages.length > 1 && (
                  <Stack direction="row" alignItems="center">
                    <Box flexGrow={1}>
                      <FormHelperText error>
                        {!!dirtyFields.contents &&
                          !!validateTranslations('contents') &&
                          formatMessage(
                            {
                              id: 'portal_item_edit.error.missing_translations',
                            },
                            {
                              missingTranslations:
                                validateTranslations('contents'),
                            },
                          )}
                      </FormHelperText>
                    </Box>
                    <Button
                      size="small"
                      startIcon={<EditIcon />}
                      onClick={handleOpenTemplateTranslationDialog}
                    >
                      {formatMessage({
                        id: 'portal_item_edit.button.manage_localization',
                      })}
                    </Button>
                  </Stack>
                )}

                <Controller
                  name={`contents.${portalSetting.defaultLanguage}`}
                  control={control}
                  rules={{
                    maxLength: TEMPLATE_MAX_LENGTH,
                    required: true,
                  }}
                  defaultValue={''}
                  render={({ field }) => (
                    <MultiLineInput
                      {...field}
                      required
                      maxLength={TEMPLATE_MAX_LENGTH}
                      error={
                        !!errors?.contents?.[portalSetting.defaultLanguage]
                      }
                      label={`${formatMessage({
                        id: 'comment_template_edit.label.template',
                      })} ${
                        supportLanguages.length < 2
                          ? ''
                          : ` (${portalSetting.defaultLanguage})`
                      }`}
                      variant="outlined"
                      fullWidth
                      rows={3}
                    />
                  )}
                />
                {errors.contents?.[portalSetting.defaultLanguage]?.type ===
                  'maxLength' && (
                  <FormHelperText error>
                    {formatMessage(
                      {
                        id: 'general.error.max_length',
                      },
                      {
                        max: TEMPLATE_MAX_LENGTH,
                      },
                    )}
                  </FormHelperText>
                )}
              </Box>
            </Stack>
          </SettingWrapper>
        </Stack>
      </form>

      <TranslationDialog
        isOpen={isTranslationDialogOpen}
        title={translationDialogTitle}
        rows={translationInputRows}
        defaultValue={getTranslationDefaultLanguageValue()}
        maxLength={maxTranslationLength}
        onSave={handleSaveTranslation}
        onClose={handleCloseTranslationDialog}
      />
    </>
  )
}

export default CommentTemplateEditPage
