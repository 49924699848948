import { useCallback, useMemo, useState } from 'react'
import { useIntl } from 'react-intl'
import useSWR, { mutate } from 'swr'
import { useRecoilValue } from 'recoil'
import { useSnackbar } from 'notistack'
import styled from '@mui/material/styles/styled'
import { type GridColDef, useGridApiRef } from '@mui/x-data-grid'
import Stack from '@mui/material/Stack'
import CircularProgress from '@mui/material/CircularProgress'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import IconButton from '@mui/material/IconButton'
import Tooltip from '@mui/material/Tooltip'
import LoadingButton from '@mui/lab/LoadingButton'
import Typography from '@mui/material/Typography'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import AddIcon from '@mui/icons-material/Add'
import DeleteIcon from '@mui/icons-material/Delete'
import InfoIcon from '@mui/icons-material/InfoOutlined'
import EditIcon from '@mui/icons-material/Edit'

import { DataTable, MainHeader, SubHeader } from 'components/StyledComponents'
import { portalSettingState } from 'state/portalSettingStates'
import useApi from 'hooks/useApi'
import { type ItemBasic } from 'components/item/itemTypes'
import usePortalSetting from 'hooks/usePortalSetting'
import { Path } from 'commonConstants'
import ErrorIcon from 'assets/icons/error_icon.svg'
import { FeatureAccess, PortalSection } from 'components/role/roleConstants'
import useMember from 'hooks/useMember'
import useRoute from 'hooks/useNavigate'
import EmptyTemplateIcon from 'assets/icons/empty_task_template.svg'
import { type TaskTemplate } from 'components/taskTemplate/taskTemplateTypes'

const Wrapper = styled(Stack)`
  border-radius: ${({ theme }) => 2 * theme.shape.borderRadius}px;
  border: 1px solid ${({ theme }) => theme.palette.divider};
  position: relative;
`

const TaskTemplateListPage: React.FC = () => {
  const { formatMessage } = useIntl()
  const { enqueueSnackbar } = useSnackbar()
  const dataTableRef = useGridApiRef()
  const { goTo } = useRoute()
  const { checkAccesses } = useMember()
  const { getLocalizedContent } = usePortalSetting()
  const portalSetting = useRecoilValue(portalSettingState)
  const { sendDeleteRequest } = useApi()
  const [isSaving, setIsSaving] = useState(false)
  const [menuEl, setMenuEl] = useState<null | HTMLElement>(null)
  const openMenu = Boolean(menuEl)
  const [selectedTemplate, setSelectedTemplate] = useState<TaskTemplate | null>(
    null,
  )
  const [isDeleteConfirmDialogOpen, setIsDeleteConfirmDialogOpen] =
    useState(false)

  const {
    data: templatesData,
    isLoading,
    mutate: mutateTemplates,
  } = useSWR<TaskTemplate[]>(
    portalSetting
      ? `${process.env.REACT_APP_API_PATH ?? ''}/portals/${
          portalSetting.id
        }/templates/tasks`
      : null,
  )

  const handleRowClick = useCallback(
    (row: TaskTemplate): void => {
      if (
        checkAccesses({
          [PortalSection.COMMENT_TEMPLATES]: [FeatureAccess.WRITE],
        }) &&
        portalSetting
      ) {
        void mutate(
          `${process.env.REACT_APP_API_PATH ?? ''}/portals/${
            portalSetting.id
          }/templates/tasks/${row.id}`,
          row,
          false,
        )

        goTo(`${Path.TASK_TEMPLATES_EDIT}/${row.id}`)
      }
    },
    [portalSetting],
  )

  const handleOpenMenu = (
    event: React.MouseEvent<HTMLElement>,
    template: TaskTemplate,
  ): void => {
    setMenuEl(event.currentTarget)
    setSelectedTemplate(template)
  }

  const handleCloseMenu = (): void => {
    setMenuEl(null)
  }

  const columns = useMemo((): GridColDef[] => {
    if (!portalSetting) {
      return []
    }

    const columnsData: GridColDef[] = [
      {
        field: 'name',
        headerName: formatMessage({ id: 'tasks_template_edit.label.name' }),
        flex: 1,
      },

      {
        field: 'items',
        headerName: formatMessage({ id: 'comment_template_list.label.items' }),
        flex: 1,
        valueGetter: (value, row) =>
          row.items
            .map((item: ItemBasic) => getLocalizedContent(item.names))
            .join(', '),
        renderCell: (params) =>
          params.row.items
            .map((item: ItemBasic) => getLocalizedContent(item.names))
            .join(', '),
      },
    ]

    if (
      checkAccesses({
        [PortalSection.COMMENT_TEMPLATES]: [FeatureAccess.WRITE],
      })
    ) {
      columnsData.push({
        field: 'action',
        type: 'actions',
        getActions: ({ row }) => [
          <IconButton
            key={row.id}
            onClick={(event) => {
              handleOpenMenu(event, row)
            }}
            size="small"
            aria-label={formatMessage({
              id: 'general.icon_button.see_more',
            })}
            role="button"
          >
            <MoreVertIcon
              sx={{
                fontSize: 16,
              }}
            />
          </IconButton>,
        ],
      })
    }

    return columnsData
  }, [portalSetting])

  const handleOpenDeleteConfirmDialog = (): void => {
    setIsDeleteConfirmDialogOpen(true)
    handleCloseMenu()
  }

  const handleCloseDeleteConfirmDialog = (): void => {
    setIsDeleteConfirmDialogOpen(false)
  }

  const deleteTemplate = useCallback(async (): Promise<void> => {
    if (!portalSetting || !selectedTemplate) {
      return
    }

    try {
      setIsSaving(true)
      await sendDeleteRequest(
        `${process.env.REACT_APP_API_PATH ?? ''}/portals/${
          portalSetting?.id
        }/templates/tasks/${selectedTemplate.id}`,
      )

      handleCloseDeleteConfirmDialog()
      enqueueSnackbar(formatMessage({ id: 'general.text.changes_saved' }), {
        variant: 'success',
      })

      await mutateTemplates()
    } catch (error) {
      console.error(error)
    } finally {
      setIsSaving(false)
    }
  }, [portalSetting, selectedTemplate])

  const handleEditTemplate = (): void => {
    if (selectedTemplate) {
      handleRowClick(selectedTemplate)
    }
  }

  return (
    <Stack height="100%" width="100%" spacing={2} overflow="auto">
      <Stack direction="row" width="100%" spacing={2}>
        <Box flexGrow={1}>
          <MainHeader>
            {formatMessage({ id: 'task_template_list.header' })}
            <Tooltip
              title={`${formatMessage({
                id: 'task_template_list.label.start_creating_template',
              })} ${formatMessage({
                id: 'task_template_list.label.creating_template_help_text',
              })}`}
            >
              <InfoIcon fontSize="small" />
            </Tooltip>
          </MainHeader>
        </Box>

        {templatesData &&
          templatesData?.length > 0 &&
          checkAccesses({
            [PortalSection.COMMENT_TEMPLATES]: [FeatureAccess.WRITE],
          }) && (
            <Button
              variant="contained"
              size="small"
              startIcon={<AddIcon />}
              onClick={() => {
                goTo(Path.TASK_TEMPLATES_ADD)
              }}
              color="secondary"
            >
              {formatMessage({
                id: 'task_template_list.button.create_template',
              })}
            </Button>
          )}
      </Stack>

      <Stack flexGrow={1} overflow="auto" spacing={2}>
        {templatesData &&
          templatesData.length === 0 &&
          checkAccesses({
            [PortalSection.COMMENT_TEMPLATES]: [FeatureAccess.WRITE],
          }) && (
            <Wrapper
              width="100%"
              height="100%"
              alignItems="center"
              justifyContent="center"
            >
              <Stack textAlign="center" spacing={2} alignItems="center">
                <EmptyTemplateIcon />
                <Box width="60%">
                  <SubHeader>
                    {formatMessage({
                      id: 'task_template_list.label.start_creating_template',
                    })}
                  </SubHeader>
                  <Typography variant="body2">
                    {formatMessage({
                      id: 'task_template_list.label.creating_template_help_text',
                    })}
                  </Typography>
                </Box>
                <Box width="100%">
                  <Button
                    variant="contained"
                    size="small"
                    startIcon={<AddIcon />}
                    onClick={() => {
                      goTo(Path.TASK_TEMPLATES_ADD)
                    }}
                    color="secondary"
                  >
                    {formatMessage({
                      id: 'task_template_list.button.create_template',
                    })}
                  </Button>
                </Box>
              </Stack>
            </Wrapper>
          )}

        {isLoading && <CircularProgress />}

        {!!templatesData?.length && !isLoading && (
          <DataTable
            apiRef={dataTableRef}
            rows={templatesData ?? []}
            columns={columns}
            initialState={{
              sorting: {
                sortModel: [{ field: 'name', sort: 'asc' }],
              },
            }}
            onRowClick={(param) => {
              handleRowClick(param.row)
            }}
            getRowHeight={() => 'auto'}
            hideFooter
            slotProps={{
              row: {
                tabIndex: 0,
                onKeyDown: (event) => {
                  if (event.key === 'Enter') {
                    const row = event.target as HTMLTableRowElement
                    row.click()
                  }
                },
              },
            }}
          />
        )}
      </Stack>

      <Menu
        anchorEl={menuEl}
        open={openMenu}
        onClose={handleCloseMenu}
        role="menu"
      >
        <MenuItem onClick={handleEditTemplate}>
          <ListItemIcon>
            <EditIcon fontSize="small" color="primary" />
          </ListItemIcon>
          <ListItemText>
            {formatMessage({
              id: 'general.icon_button.edit',
            })}
          </ListItemText>
        </MenuItem>

        {selectedTemplate?._operations.canDelete && (
          <MenuItem onClick={handleOpenDeleteConfirmDialog}>
            <ListItemIcon>
              <DeleteIcon fontSize="small" color="primary" />
            </ListItemIcon>
            <ListItemText>
              {formatMessage({
                id: 'general.icon_button.delete',
              })}
            </ListItemText>
          </MenuItem>
        )}
      </Menu>

      <Dialog open={isDeleteConfirmDialogOpen} maxWidth="xs">
        <DialogTitle textAlign="center">
          <Stack alignItems="center" spacing={2}>
            <ErrorIcon />
            <Box textAlign="center">
              {formatMessage({
                id: 'task_template_list.dialog.delete.title',
              })}
            </Box>
          </Stack>
        </DialogTitle>
        <DialogContent sx={{ textAlign: 'center' }}>
          {formatMessage({ id: 'task_template_list.dialog.delete.content' })}
        </DialogContent>
        <DialogActions>
          <Stack width="100%" spacing={1}>
            <LoadingButton
              loading={isSaving}
              fullWidth
              onClick={(): void => {
                void deleteTemplate()
              }}
              autoFocus
              variant="contained"
              color="secondary"
            >
              {formatMessage({
                id: 'general.icon_button.delete',
              })}
            </LoadingButton>
            <Button
              fullWidth
              onClick={handleCloseDeleteConfirmDialog}
              variant="outlined"
              color="secondary"
            >
              {formatMessage({
                id: 'general.button.cancel',
              })}
            </Button>
          </Stack>
        </DialogActions>
      </Dialog>
    </Stack>
  )
}

export default TaskTemplateListPage
