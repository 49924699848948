import { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import useSWR from 'swr'
import { useRecoilValue } from 'recoil'
import Stack from '@mui/material/Stack'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress'
import EditIcon from '@mui/icons-material/Edit'
import CodeIcon from '@mui/icons-material/Code'

import { portalSettingState } from 'state/portalSettingStates'
import {
  type CategoriesData,
  type Category,
} from 'components/categoryV2/categoryTypes'
import useRoute from 'hooks/useNavigate'
import { userAccessState } from 'state/userStates'
import { IconButton } from '@mui/material'
import { Path } from 'commonConstants'
import { MainHeader, SubHeader } from 'components/StyledComponents'
import { FeatureAccess, PortalSection } from 'components/role/roleConstants'
import EmptyCategoryIcon from 'assets/icons/empty_category.svg'
import useMember from 'hooks/useMember'
import CategoryListTree from 'components/categoryV2/list/CategoryListTree'

const CategoryListPage: React.FC = () => {
  const { formatMessage } = useIntl()
  const { goTo } = useRoute()
  const portalSetting = useRecoilValue(portalSettingState)
  const userAccess = useRecoilValue(userAccessState)
  const [categories, setCategories] = useState<Category[]>([])
  const { data: categoriesData, isLoading } = useSWR<CategoriesData>(
    portalSetting
      ? `${process.env.REACT_APP_API_BASE_PATH ?? ''}/v2/portals/${
          portalSetting.id
        }/categories/root`
      : null,
  )
  const { checkAccesses } = useMember()

  useEffect(() => {
    if (categoriesData) {
      setCategories(categoriesData.categories)
    }
  }, [categoriesData])

  return (
    <Stack height="100%" width="100%" spacing={2} overflow="auto">
      <Stack direction="row" width="100%" spacing={2}>
        <Box flexGrow={1}>
          <MainHeader>
            {formatMessage({ id: 'edit_categories.header' })}
          </MainHeader>
        </Box>

        {categoriesData &&
          categoriesData?.categories.length > 0 &&
          checkAccesses({
            [PortalSection.CATEGORIES]: [FeatureAccess.WRITE],
          }) && (
            <Button
              variant="contained"
              size="small"
              startIcon={<EditIcon />}
              onClick={() => {
                goTo(Path.CATEGORIES_V2_EDIT)
              }}
              color="secondary"
            >
              {formatMessage({
                id: 'edit_categories.button.edit_categories',
              })}
            </Button>
          )}

        {userAccess?.userData.admin && (
          <IconButton
            size="small"
            color="secondary"
            onClick={() => {
              goTo(Path.CATEGORIES_V2_EDITOR)
            }}
            aria-label="Code editor"
          >
            <CodeIcon />
          </IconButton>
        )}
      </Stack>

      {!isLoading &&
        !categoriesData?.categories?.length &&
        checkAccesses({
          [PortalSection.CATEGORIES]: [FeatureAccess.WRITE],
        }) && (
          <Stack
            width="100%"
            height="100%"
            alignItems="center"
            justifyContent="center"
            overflow={'auto'}
          >
            <Stack textAlign="center" spacing={2} alignItems="center">
              <EmptyCategoryIcon />
              <Box width="100%">
                <SubHeader>
                  {formatMessage({
                    id: 'edit_categories.label.start_creating_categories',
                  })}
                </SubHeader>
              </Box>
              <Box width="100%">
                <Button
                  variant="contained"
                  size="small"
                  startIcon={<EditIcon />}
                  onClick={() => {
                    goTo(Path.CATEGORIES_V2_EDIT)
                  }}
                  color="secondary"
                >
                  {formatMessage({
                    id: 'edit_categories.button.edit_categories',
                  })}
                </Button>
              </Box>
            </Stack>
          </Stack>
        )}

      <Box flexGrow={1} overflow={'auto'}>
        {isLoading && <CircularProgress />}

        {!isLoading && (
          <Stack spacing={1}>
            <CategoryListTree categories={categories} />
          </Stack>
        )}
      </Box>
    </Stack>
  )
}

export default CategoryListPage
