import { useEffect, useLayoutEffect, useState, type MouseEvent } from 'react'
import { useIntl } from 'react-intl'
import { useLocation } from 'react-router-dom'
import { useRecoilState, useRecoilValue } from 'recoil'
import styled from '@mui/material/styles/styled'
import Stack from '@mui/material/Stack'
import MenuList from '@mui/material/MenuList'
import MenuItem from '@mui/material/MenuItem'
import ListItemText from '@mui/material/ListItemText'
import Box from '@mui/material/Box'
import ListItemIcon from '@mui/material/ListItemIcon'
import CaseIcon from '@mui/icons-material/AssignmentOutlined'
import CategoryIcon from '@mui/icons-material/Category'
import PortalListIcon from '@mui/icons-material/HomeWork'
import ItemIcon from '@mui/icons-material/FolderCopy'
import SettingsIcon from '@mui/icons-material/Settings'
import MemberIcon from '@mui/icons-material/Badge'
import RoleIcon from '@mui/icons-material/Groups'
import CustomerIcon from '@mui/icons-material/Person'
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'
import Menu from '@mui/material/Menu'
import MuiAccordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import AlertIcon from '@mui/icons-material/Report'
import PollIcon from '@mui/icons-material/BarChart'
import CommentTemplatesIcon from '@mui/icons-material/Comment'
import PeopleManagementIcon from '@mui/icons-material/PeopleAlt'
import PortalManagementIcon from '@mui/icons-material/Home'

import useRoute from '../hooks/useNavigate'
import { portalSettingState } from 'state/portalSettingStates'
import { isMenuOpenState, screenSizeState } from 'state/layoutStates'
import {
  MenuMode,
  PATH_ACCESSES,
  PREPORTI_SUPPORT_LOCALES_MAP,
  Path,
  SMALL_SCREEN_SIZE,
} from '../commonConstants'
import useMember from 'hooks/useMember'
import { userAccessState } from 'state/userStates'
import {
  ForwardingContactIcon,
  TeamIcon,
  NewsIcon,
  ShownMenuIcon,
  HiddenMenuIcon,
  CaseManagementIcon,
  EportiSupportIcon,
  TaskTemplateIcon,
  CollaborationIcon,
} from 'components/icons/Icons'

export const MENU_FULL_WIDTH = 300
export const MENU_FOLDED_WIDTH = 90

export const MenuWrapper = styled(Stack)`
  box-shadow: 0px 10px 30px 0px rgba(17, 38, 146, 0.05);
  background: ${({ theme }) => theme.palette.background.paper};
  border-top-right-radius: ${({ theme }) => 2 * theme.shape.borderRadius}px;
  border-bottom-right-radius: ${({ theme }) => 2 * theme.shape.borderRadius}px;
  height: 100%;
  padding: 8px;

  & .MuiList-root {
    & .MuiMenuItem-root {
      padding: 14px;
      border-radius: ${({ theme }) => 2 * theme.shape.borderRadius}px;

      :hover {
        background: ${({ theme }) => theme.palette.primary.light};
      }

      & .MuiListItemText-primary {
        font-size: 14px;
        line-height: 14px;
        font-weight: 500;
        white-space: normal;
      }

      & svg {
        color: ${({ theme }) => theme.palette.text.primary};
      }
    }

    & .Mui-selected,
    & .Mui-selected:hover {
      background-color: ${({ theme }) => theme.palette.primary.main};
      color: ${({ theme }) => theme.palette.primary.contrastText};

      & svg {
        color: ${({ theme }) => theme.palette.primary.contrastText};
      }
    }
  }
`

const HideMenuButton = styled(Button)`
  font-size: 12px;
  font-weight: 400;
`

const Accordion = styled(MuiAccordion)`
  border: none;
  box-shadow: none;
  border-radius: ${({ theme }) => 2 * theme.shape.borderRadius}px;

  &.Mui-expanded {
    background-color: ${({ theme }) => theme.palette.info.light};
  }

  &:before {
    display: 'none';
    background-color: transparent;
    top: 0px;
  }

  &:not(:first-of-type) {
    margin-top: 14px;
  }

  &:first-of-type,
  &:last-of-type {
    border-radius: ${({ theme }) => 2 * theme.shape.borderRadius}px;
  }

  .MuiAccordionSummary-root,
  .MuiAccordionSummary-root.Mui-expanded {
    min-height: 0;
  }

  .MuiAccordionSummary-content {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 14px;
    margin: 14px 0px;
    font-size: 14px;
    line-height: 14px;
    font-weight: 500;
  }

  .MuiAccordionDetails-root {
    padding: 0 14px;

    .MuiListItemText-root .MuiListItemText-primary {
      font-size: 12px;
    }
  }

  .MuiAccordionSummary-expandIconWrapper {
    background: transparent;
    color: inherit;
  }
`

const MainMenu: React.FC = () => {
  const { goTo } = useRoute()
  const location = useLocation()
  const { formatMessage, locale } = useIntl()
  const portalSetting = useRecoilValue(portalSettingState)
  const [isOpen, setIsOpen] = useRecoilState(isMenuOpenState)
  const { checkAccesses } = useMember()
  const userAccess = useRecoilValue(userAccessState)
  const screenSize = useRecoilValue(screenSizeState)
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const openSupport = Boolean(anchorEl)
  const [openedMenus, setOpenedMenus] = useState<Set<string>>(
    new Set([
      'caseManagement',
      'peopleManagement',
      'collaborationEngagement',
      'portalManagement',
    ]),
  )

  useEffect(() => {
    const newOpenedMenus = new Set<string>(openedMenus)
    const caseManagement = localStorage.getItem('caseManagement')
    if (caseManagement === MenuMode.CLOSED) {
      newOpenedMenus.delete('caseManagement')
    }

    const peopleManagement = localStorage.getItem('peopleManagement')
    if (peopleManagement === MenuMode.CLOSED) {
      newOpenedMenus.delete('peopleManagement')
    }

    const collaborationEngagement = localStorage.getItem(
      'collaborationEngagement',
    )
    if (collaborationEngagement === MenuMode.CLOSED) {
      newOpenedMenus.delete('collaborationEngagement')
    }

    const portalManagement = localStorage.getItem('portalManagement')
    if (portalManagement === MenuMode.CLOSED) {
      newOpenedMenus.delete('portalManagement')
    }

    setOpenedMenus(newOpenedMenus)
  }, [])

  useEffect(() => {
    localStorage.setItem(
      'isMenuOpen',
      isOpen ? MenuMode.OPENED : MenuMode.CLOSED,
    )
  }, [isOpen])

  const handleOpenSupportMenu = (event: MouseEvent<HTMLElement>): void => {
    setAnchorEl(event.currentTarget)
  }
  const handleCloseSupportMenu = (): void => {
    setAnchorEl(null)
  }

  const toggleMenu = (): void => {
    setIsOpen((prev) => !prev)
  }

  useLayoutEffect(() => {
    if (screenSize.width < SMALL_SCREEN_SIZE) {
      setIsOpen(false)
    }
  }, [screenSize])

  const handleChange =
    (menu: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      const newOpenedMenus = new Set<string>(openedMenus)
      if (isExpanded) {
        newOpenedMenus.add(menu)
      } else {
        newOpenedMenus.delete(menu)
      }
      setOpenedMenus(newOpenedMenus)
      localStorage.setItem(menu, isExpanded ? MenuMode.OPENED : MenuMode.CLOSED)
    }

  return (
    <MenuWrapper
      width={isOpen ? `${MENU_FULL_WIDTH}px` : `${MENU_FOLDED_WIDTH}px`}
    >
      <Stack height={'100%'}>
        <Box paddingX={1}>
          {isOpen && (
            <HideMenuButton
              onClick={toggleMenu}
              startIcon={
                <ShownMenuIcon
                  aria-label={formatMessage({
                    id: 'main_menu.hide_menu',
                  })}
                />
              }
            >
              {formatMessage({
                id: 'main_menu.hide_menu',
              })}
            </HideMenuButton>
          )}

          {!isOpen && (
            <IconButton
              size="small"
              onClick={toggleMenu}
              color="primary"
              sx={{ marginLeft: isOpen ? 0 : 1.3 }}
            >
              <HiddenMenuIcon
                fontSize="small"
                aria-label={formatMessage({
                  id: 'main_menu.show_menu',
                })}
              />
            </IconButton>
          )}
        </Box>

        <Stack flex={1} overflow="auto">
          {portalSetting && (
            <>
              <Box>
                {checkAccesses(PATH_ACCESSES[Path.CASES_LIST]) && (
                  <MenuList>
                    <MenuItem
                      selected={
                        location.pathname.includes('cases') ||
                        location.pathname === '/'
                      }
                      onClick={(): void => {
                        goTo(Path.CASES_LIST)
                      }}
                      tabIndex={0}
                    >
                      <ListItemIcon sx={{ paddingLeft: isOpen ? 0 : 1.3 }}>
                        <CaseIcon fontSize="small" />
                      </ListItemIcon>
                      {isOpen && (
                        <ListItemText>
                          {formatMessage({ id: 'main_menu.cases' })}
                        </ListItemText>
                      )}
                    </MenuItem>
                  </MenuList>
                )}

                {(checkAccesses(PATH_ACCESSES[Path.CONTACTS_LIST]) ||
                  checkAccesses(PATH_ACCESSES[Path.TASK_TEMPLATES_LIST]) ||
                  checkAccesses(PATH_ACCESSES[Path.COMMENT_TEMPLATES_LIST]) ||
                  checkAccesses(PATH_ACCESSES[Path.CATEGORIES])) && (
                  <Accordion
                    onChange={handleChange('caseManagement')}
                    expanded={openedMenus.has('caseManagement')}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon fontSize="small" />}
                      aria-controls="case-management-content"
                      id="case-management-header"
                    >
                      <CaseManagementIcon fontSize="small" />
                      {isOpen &&
                        formatMessage({ id: 'main_menu.case_management' })}
                    </AccordionSummary>
                    <AccordionDetails>
                      <MenuList>
                        {checkAccesses(PATH_ACCESSES[Path.CONTACTS_LIST]) && (
                          <MenuItem
                            selected={location.pathname.includes(
                              Path.CONTACTS_LIST,
                            )}
                            onClick={(): void => {
                              goTo(Path.CONTACTS_LIST)
                            }}
                            tabIndex={0}
                          >
                            <ListItemIcon>
                              <ForwardingContactIcon fontSize="small" />
                            </ListItemIcon>
                            {isOpen && (
                              <ListItemText>
                                {formatMessage({ id: 'contact_list.header' })}
                              </ListItemText>
                            )}
                          </MenuItem>
                        )}

                        {checkAccesses(
                          PATH_ACCESSES[Path.TASK_TEMPLATES_LIST],
                        ) && (
                          <MenuItem
                            selected={location.pathname.includes(
                              Path.TASK_TEMPLATES_LIST,
                            )}
                            onClick={(): void => {
                              goTo(Path.TASK_TEMPLATES_LIST)
                            }}
                            tabIndex={0}
                          >
                            <ListItemIcon>
                              <TaskTemplateIcon fontSize="small" />
                            </ListItemIcon>
                            {isOpen && (
                              <ListItemText>
                                {formatMessage({
                                  id: 'task_template_list.header',
                                })}
                              </ListItemText>
                            )}
                          </MenuItem>
                        )}

                        {checkAccesses(
                          PATH_ACCESSES[Path.COMMENT_TEMPLATES_LIST],
                        ) && (
                          <MenuItem
                            selected={location.pathname.includes(
                              Path.COMMENT_TEMPLATES_LIST,
                            )}
                            onClick={(): void => {
                              goTo(Path.COMMENT_TEMPLATES_LIST)
                            }}
                            tabIndex={0}
                          >
                            <ListItemIcon>
                              <CommentTemplatesIcon fontSize="small" />
                            </ListItemIcon>
                            {isOpen && (
                              <ListItemText>
                                {formatMessage({
                                  id: 'comment_template_list.header',
                                })}
                              </ListItemText>
                            )}
                          </MenuItem>
                        )}

                        {checkAccesses(PATH_ACCESSES[Path.CATEGORIES]) && (
                          <MenuItem
                            selected={
                              location.pathname.includes(Path.CATEGORIES) &&
                              !location.pathname.includes(
                                Path.CATEGORIES_V2_LIST,
                              )
                            }
                            onClick={(): void => {
                              goTo(Path.CATEGORIES)
                            }}
                            tabIndex={0}
                          >
                            <ListItemIcon>
                              <CategoryIcon fontSize="small" />
                            </ListItemIcon>
                            {isOpen && (
                              <ListItemText>
                                {formatMessage({
                                  id: 'edit_categories.header',
                                })}
                              </ListItemText>
                            )}
                          </MenuItem>
                        )}

                        {checkAccesses(PATH_ACCESSES[Path.CATEGORIES]) &&
                          userAccess?.userData.admin && (
                            <MenuItem
                              selected={location.pathname.includes(
                                Path.CATEGORIES_V2_LIST,
                              )}
                              onClick={(): void => {
                                goTo(Path.CATEGORIES_V2_LIST)
                              }}
                              tabIndex={0}
                            >
                              <ListItemIcon>
                                <CategoryIcon fontSize="small" />
                              </ListItemIcon>
                              {isOpen && (
                                <ListItemText>
                                  {formatMessage({
                                    id: 'edit_categories.header',
                                  })}{' '}
                                  v2
                                </ListItemText>
                              )}
                            </MenuItem>
                          )}
                      </MenuList>
                    </AccordionDetails>
                  </Accordion>
                )}

                {(checkAccesses(PATH_ACCESSES[Path.MEMBERS_LIST]) ||
                  checkAccesses(PATH_ACCESSES[Path.TEAMS_LIST]) ||
                  checkAccesses(PATH_ACCESSES[Path.CUSTOMERS_LIST])) && (
                  <Accordion
                    onChange={handleChange('peopleManagement')}
                    expanded={openedMenus.has('peopleManagement')}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon fontSize="small" />}
                      aria-controls="people-management-content"
                      id="people-management-header"
                    >
                      <PeopleManagementIcon fontSize="small" />
                      {isOpen &&
                        formatMessage({ id: 'main_menu.people_management' })}
                    </AccordionSummary>
                    <AccordionDetails>
                      <MenuList>
                        {checkAccesses(PATH_ACCESSES[Path.MEMBERS_LIST]) && (
                          <MenuItem
                            selected={location.pathname.includes(
                              Path.MEMBERS_LIST,
                            )}
                            onClick={(): void => {
                              goTo(Path.MEMBERS_LIST)
                            }}
                            tabIndex={0}
                          >
                            <ListItemIcon>
                              <MemberIcon fontSize="small" />
                            </ListItemIcon>
                            {isOpen && (
                              <ListItemText>
                                {formatMessage({ id: 'member_list.header' })}
                              </ListItemText>
                            )}
                          </MenuItem>
                        )}

                        {checkAccesses(PATH_ACCESSES[Path.TEAMS_LIST]) && (
                          <MenuItem
                            selected={location.pathname.includes(
                              Path.TEAMS_LIST,
                            )}
                            onClick={(): void => {
                              goTo(Path.TEAMS_LIST)
                            }}
                            tabIndex={0}
                          >
                            <ListItemIcon>
                              <TeamIcon fontSize="small" />
                            </ListItemIcon>
                            {isOpen && (
                              <ListItemText>
                                {formatMessage({ id: 'team_list.header' })}
                              </ListItemText>
                            )}
                          </MenuItem>
                        )}

                        {checkAccesses(PATH_ACCESSES[Path.CUSTOMERS_LIST]) && (
                          <MenuItem
                            selected={location.pathname.includes(
                              Path.CUSTOMERS_LIST,
                            )}
                            onClick={(): void => {
                              goTo(Path.CUSTOMERS_LIST)
                            }}
                            tabIndex={0}
                          >
                            <ListItemIcon>
                              <CustomerIcon fontSize="small" />
                            </ListItemIcon>
                            {isOpen && (
                              <ListItemText>
                                {formatMessage({ id: 'customer_list.header' })}
                              </ListItemText>
                            )}
                          </MenuItem>
                        )}
                      </MenuList>
                    </AccordionDetails>
                  </Accordion>
                )}

                {(checkAccesses(PATH_ACCESSES[Path.POLLS_LIST]) ||
                  checkAccesses(PATH_ACCESSES[Path.NEWS_LIST]) ||
                  checkAccesses(PATH_ACCESSES[Path.ALERTS_LIST])) && (
                  <Accordion
                    onChange={handleChange('collaborationEngagement')}
                    expanded={openedMenus.has('collaborationEngagement')}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon fontSize="small" />}
                      aria-controls="collaboration-engagement-content"
                      id="collaboration-engagement-header"
                    >
                      <CollaborationIcon fontSize="small" />
                      {isOpen &&
                        formatMessage({
                          id: 'main_menu.collaboration_engagement',
                        })}
                    </AccordionSummary>
                    <AccordionDetails>
                      <MenuList>
                        {checkAccesses(PATH_ACCESSES[Path.POLLS_LIST]) && (
                          <MenuItem
                            selected={location.pathname.includes(
                              Path.POLLS_LIST,
                            )}
                            onClick={(): void => {
                              goTo(Path.POLLS_LIST)
                            }}
                            tabIndex={0}
                          >
                            <ListItemIcon>
                              <PollIcon fontSize="small" />
                            </ListItemIcon>
                            {isOpen && (
                              <ListItemText>
                                {formatMessage({ id: 'poll_list.header' })}
                              </ListItemText>
                            )}
                          </MenuItem>
                        )}

                        {checkAccesses(PATH_ACCESSES[Path.NEWS_LIST]) && (
                          <MenuItem
                            selected={location.pathname.includes(
                              Path.NEWS_LIST,
                            )}
                            onClick={(): void => {
                              goTo(Path.NEWS_LIST)
                            }}
                            tabIndex={0}
                          >
                            <ListItemIcon>
                              <NewsIcon fontSize="small" />
                            </ListItemIcon>
                            {isOpen && (
                              <ListItemText>
                                {formatMessage({ id: 'news_list.header' })}
                              </ListItemText>
                            )}
                          </MenuItem>
                        )}

                        {checkAccesses(PATH_ACCESSES[Path.ALERTS_LIST]) && (
                          <MenuItem
                            selected={location.pathname.includes(
                              Path.ALERTS_LIST,
                            )}
                            onClick={(): void => {
                              goTo(Path.ALERTS_LIST)
                            }}
                            tabIndex={0}
                          >
                            <ListItemIcon>
                              <AlertIcon fontSize="small" />
                            </ListItemIcon>
                            {isOpen && (
                              <ListItemText>
                                {formatMessage({ id: 'alert_list.header' })}
                              </ListItemText>
                            )}
                          </MenuItem>
                        )}
                      </MenuList>
                    </AccordionDetails>
                  </Accordion>
                )}

                {(checkAccesses(PATH_ACCESSES[Path.ITEMS_LIST]) ||
                  checkAccesses(PATH_ACCESSES[Path.ROLES_LIST]) ||
                  checkAccesses(PATH_ACCESSES[Path.PORTALS_EDIT])) && (
                  <Accordion
                    onChange={handleChange('portalManagement')}
                    expanded={openedMenus.has('portalManagement')}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon fontSize="small" />}
                      aria-controls="portal-management-content"
                      id="portal-management-header"
                    >
                      <PortalManagementIcon fontSize="small" />
                      {isOpen &&
                        formatMessage({
                          id: 'main_menu.portal_management',
                        })}
                    </AccordionSummary>
                    <AccordionDetails>
                      <MenuList>
                        {checkAccesses(PATH_ACCESSES[Path.PORTALS_EDIT]) && (
                          <MenuItem
                            selected={location.pathname.includes(
                              Path.PORTALS_EDIT,
                            )}
                            onClick={(): void => {
                              goTo(`${Path.PORTALS_EDIT}/${portalSetting.id}`)
                            }}
                            tabIndex={0}
                          >
                            <ListItemIcon>
                              <SettingsIcon fontSize="small" />
                            </ListItemIcon>
                            {isOpen && (
                              <ListItemText>
                                {formatMessage({
                                  id: 'main_menu.general_settings',
                                })}
                              </ListItemText>
                            )}
                          </MenuItem>
                        )}

                        {checkAccesses(PATH_ACCESSES[Path.ITEMS_LIST]) && (
                          <MenuItem
                            selected={location.pathname.includes(
                              Path.ITEMS_LIST,
                            )}
                            onClick={(): void => {
                              goTo(Path.ITEMS_LIST)
                            }}
                            tabIndex={0}
                          >
                            <ListItemIcon>
                              <ItemIcon fontSize="small" />
                            </ListItemIcon>
                            {isOpen && (
                              <ListItemText>
                                {formatMessage({
                                  id: 'portal_item_list.header',
                                })}
                              </ListItemText>
                            )}
                          </MenuItem>
                        )}

                        {checkAccesses(PATH_ACCESSES[Path.ROLES_LIST]) && (
                          <MenuItem
                            selected={location.pathname.includes(
                              Path.ROLES_LIST,
                            )}
                            onClick={(): void => {
                              goTo(`${Path.ROLES_LIST}/0`)
                            }}
                            tabIndex={0}
                          >
                            <ListItemIcon>
                              <RoleIcon fontSize="small" />
                            </ListItemIcon>
                            {isOpen && (
                              <ListItemText>
                                {formatMessage({
                                  id: 'member_role_list.header',
                                })}
                              </ListItemText>
                            )}
                          </MenuItem>
                        )}
                      </MenuList>
                    </AccordionDetails>
                  </Accordion>
                )}
              </Box>
            </>
          )}

          <Box>
            {(userAccess?.userData.admin ||
              (userAccess?.accessiblePortals &&
                userAccess?.accessiblePortals.length > 1)) && (
              <MenuList>
                <MenuItem
                  selected={location.pathname === Path.PORTALS_LIST}
                  onClick={(): void => {
                    goTo(Path.PORTALS_LIST)
                  }}
                  tabIndex={0}
                >
                  <ListItemIcon sx={{ paddingLeft: isOpen ? 0 : 1.3 }}>
                    <PortalListIcon fontSize="small" />
                  </ListItemIcon>
                  {isOpen && (
                    <ListItemText>
                      {formatMessage({ id: 'portal_list.header' })}
                    </ListItemText>
                  )}
                </MenuItem>
              </MenuList>
            )}
          </Box>
        </Stack>

        <Stack paddingX={1} flexDirection="column-reverse">
          <Box>
            {isOpen && (
              <Button
                id="support-button"
                aria-controls={openSupport ? 'support-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={openSupport ? 'true' : undefined}
                startIcon={
                  <EportiSupportIcon
                    aria-label={formatMessage({
                      id: 'main_menu.eporti_support',
                    })}
                  />
                }
                onClick={handleOpenSupportMenu}
              >
                {formatMessage({ id: 'main_menu.eporti_support' })}
              </Button>
            )}

            {!isOpen && (
              <IconButton
                size="small"
                onClick={handleOpenSupportMenu}
                color="primary"
                sx={{ marginLeft: isOpen ? 0 : 1.3 }}
              >
                <EportiSupportIcon
                  fontSize="small"
                  aria-label={formatMessage({
                    id: 'main_menu.eporti_support',
                  })}
                />
              </IconButton>
            )}
          </Box>

          <Menu
            id="support-menu"
            aria-labelledby="support-button"
            anchorEl={anchorEl}
            open={openSupport}
            onClose={handleCloseSupportMenu}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
          >
            <MenuItem
              onClick={() => {
                window.open(
                  `https://www.preporti.com/${
                    PREPORTI_SUPPORT_LOCALES_MAP[locale] ?? ''
                  }faq`,
                  '_blank',
                )
                handleCloseSupportMenu()
              }}
            >
              {formatMessage({ id: 'login_footer.link.faq' })}
            </MenuItem>
            <MenuItem
              onClick={() => {
                window.open(
                  `https://www.preporti.com/${
                    PREPORTI_SUPPORT_LOCALES_MAP[locale] ?? ''
                  }terms-of-use`,
                  '_blank',
                )
                handleCloseSupportMenu()
              }}
            >
              {formatMessage({ id: 'login_footer.link.terms' })}
            </MenuItem>
            <MenuItem
              onClick={() => {
                window.open(
                  `https://www.preporti.com/${
                    PREPORTI_SUPPORT_LOCALES_MAP[locale] ?? ''
                  }privacy-policy`,
                  '_blank',
                )
                handleCloseSupportMenu()
              }}
            >
              {formatMessage({
                id: 'login_footer.link.privacy_policy',
              })}
            </MenuItem>
            <MenuItem
              onClick={() => {
                window.open(
                  `https://www.preporti.com/${
                    PREPORTI_SUPPORT_LOCALES_MAP[locale] ?? ''
                  }`,
                  '_blank',
                )
                handleCloseSupportMenu()
              }}
            >
              {formatMessage({ id: 'login_footer.link.about_us' })}
            </MenuItem>
          </Menu>
        </Stack>
      </Stack>
    </MenuWrapper>
  )
}

export default MainMenu
