import { useCallback, useContext } from 'react'
import { getAuth, signOut } from 'firebase/auth'
import { useRecoilValue, useSetRecoilState, useRecoilState } from 'recoil'

import { type BasicUserInfo } from 'types'
import AuthContext from 'context/AuthContext'
import { portalSettingState } from 'state/portalSettingStates'
import { hasUnsavedChangesState } from 'state/formStates'
import { currentPortalAccessSelector } from 'state/memberStates'
import { type PortalSectionAccesses } from 'components/role/roleTypes'
import { userAccessState } from 'state/userStates'

type UseUserReturn = {
  userInfo?: BasicUserInfo
  clearSession: () => void
  checkAccesses: (accesses?: PortalSectionAccesses) => boolean
}

const useMember = (): UseUserReturn => {
  const { clearAuthData } = useContext(AuthContext)
  const setPortalSetting = useSetRecoilState(portalSettingState)
  const setHasUnsavedChanges = useSetRecoilState(hasUnsavedChangesState)
  const [userAccess, setUserAccess] = useRecoilState(userAccessState)
  const currentPortalAccess = useRecoilValue(currentPortalAccessSelector)

  const checkAccesses = useCallback(
    (accesses?: PortalSectionAccesses): boolean => {
      if (userAccess?.userData.admin) {
        return true
      }

      if (!currentPortalAccess) {
        return false
      }

      if (currentPortalAccess) {
        let hasAccess = true

        if (hasAccess && accesses?.CASES) {
          hasAccess =
            !!currentPortalAccess.accessibleSections.CASES &&
            accesses.CASES.includes(
              currentPortalAccess.accessibleSections.CASES,
            )
        }

        if (hasAccess && accesses?.ITEMS) {
          hasAccess =
            !!currentPortalAccess.accessibleSections.ITEMS &&
            accesses.ITEMS.includes(
              currentPortalAccess.accessibleSections.ITEMS,
            )
        }

        if (hasAccess && accesses?.CATEGORIES) {
          hasAccess =
            !!currentPortalAccess.accessibleSections.CATEGORIES &&
            accesses.CATEGORIES.includes(
              currentPortalAccess.accessibleSections.CATEGORIES,
            )
        }

        if (hasAccess && accesses?.ROLES) {
          hasAccess =
            !!currentPortalAccess.accessibleSections.ROLES &&
            accesses.ROLES.includes(
              currentPortalAccess.accessibleSections.ROLES,
            )
        }

        if (hasAccess && accesses?.MEMBERS) {
          hasAccess =
            !!currentPortalAccess.accessibleSections.MEMBERS &&
            accesses.MEMBERS.includes(
              currentPortalAccess.accessibleSections.MEMBERS,
            )
        }

        if (hasAccess && accesses?.TEAMS) {
          hasAccess =
            !!currentPortalAccess.accessibleSections.TEAMS &&
            accesses.TEAMS.includes(
              currentPortalAccess.accessibleSections.TEAMS,
            )
        }

        if (hasAccess && accesses?.SETTINGS) {
          hasAccess =
            !!currentPortalAccess.accessibleSections.SETTINGS &&
            accesses.SETTINGS.includes(
              currentPortalAccess.accessibleSections.SETTINGS,
            )
        }

        if (hasAccess && accesses?.CUSTOMERS) {
          hasAccess =
            !!currentPortalAccess.accessibleSections.CUSTOMERS &&
            accesses.CUSTOMERS.includes(
              currentPortalAccess.accessibleSections.CUSTOMERS,
            )
        }

        if (hasAccess && accesses?.ALERTS) {
          hasAccess =
            !!currentPortalAccess.accessibleSections.ALERTS &&
            accesses.ALERTS.includes(
              currentPortalAccess.accessibleSections.ALERTS,
            )
        }

        if (hasAccess && accesses?.POLLS) {
          hasAccess =
            !!currentPortalAccess.accessibleSections.POLLS &&
            accesses.POLLS.includes(
              currentPortalAccess.accessibleSections.POLLS,
            )
        }

        if (hasAccess && accesses?.CONTACTS) {
          hasAccess =
            !!currentPortalAccess.accessibleSections.CONTACTS &&
            accesses.CONTACTS.includes(
              currentPortalAccess.accessibleSections.CONTACTS,
            )
        }

        if (hasAccess && accesses?.COMMENT_TEMPLATES) {
          hasAccess =
            !!currentPortalAccess.accessibleSections.COMMENT_TEMPLATES &&
            accesses.COMMENT_TEMPLATES.includes(
              currentPortalAccess.accessibleSections.COMMENT_TEMPLATES,
            )
        }

        if (hasAccess && accesses?.TASK_TEMPLATES) {
          hasAccess =
            !!currentPortalAccess.accessibleSections.TASK_TEMPLATES &&
            accesses.TASK_TEMPLATES.includes(
              currentPortalAccess.accessibleSections.TASK_TEMPLATES,
            )
        }

        if (hasAccess && accesses?.NEWS_POSTS) {
          hasAccess =
            !!currentPortalAccess.accessibleSections.NEWS_POSTS &&
            accesses.NEWS_POSTS.includes(
              currentPortalAccess.accessibleSections.NEWS_POSTS,
            )
        }

        return hasAccess
      }

      return false
    },
    [userAccess, currentPortalAccess],
  )

  const clearSession = useCallback((): void => {
    void signOut(getAuth())
    clearAuthData()
    setUserAccess(null)
    setPortalSetting(null)
    setHasUnsavedChanges(false)
  }, [])

  return {
    clearSession,
    checkAccesses,
  }
}

export default useMember
