import { useCallback, useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { useRecoilValue, useRecoilState } from 'recoil'
import styled from '@mui/material/styles/styled'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Stack from '@mui/material/Stack'
import IconButton from '@mui/material/IconButton'
import Avatar from '@mui/material/Avatar'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import Tooltip, {
  type TooltipProps,
  tooltipClasses,
} from '@mui/material/Tooltip'
import LogoutIcon from '@mui/icons-material/Logout'
import DarkThemeIcon from '@mui/icons-material/Brightness4'
import LightThemeIcon from '@mui/icons-material/Brightness7'

import {
  currentLocaleState,
  portalSettingState,
} from 'state/portalSettingStates'
import {
  Path,
  PortalType,
  SUPPORT_LOCALE_LABEL,
  ThemeMode,
} from '../commonConstants'
import { type Locale } from 'types'
import useRoute from 'hooks/useNavigate'
import { nameInitials } from 'utils/stringUtils'
import { themeModeState } from 'state/layoutStates'
import { userAccessState } from 'state/userStates'
import { currentPortalAccessSelector } from 'state/memberStates'
import { getThumbnailUrl } from 'utils/fileUtils'
import { Typography } from '@mui/material'
import { InfoText } from 'components/StyledComponents'

export const TopBannerWrapper = styled(Stack)`
  box-shadow: 0px 1px 5px 0px #1126920d;
  background: ${({ theme }) => theme.palette.background.paper};
  height: 56px;
  justify-content: center;
`

const LogoWrapper = styled(Box)`
  position: relative;
  display: flex;
  overflow: hidden;
  max-height: 50px;

  & img {
    max-width: 100%;
    max-height: 50px;
    display: block;
    cursor: pointer;
  }

  font-size: 24px;
  font-weight: 500;
  align-items: center;
  gap: 10px;
`

const UserInfoWrapper = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[4],
    borderRadius: 2 * theme.shape.borderRadius,
    color: theme.palette.text.primary,
    padding: theme.spacing(2),
  },
}))

const TopBanner: React.FC = () => {
  const { formatMessage } = useIntl()
  const { goTo } = useRoute()
  const userAccess = useRecoilValue(userAccessState)
  const portalSetting = useRecoilValue(portalSettingState)
  const [userLanguage, setUserLanguage] = useState('')
  const [currentLocale, setCurrentLocale] = useRecoilState(currentLocaleState)
  const [themeMode, setThemeMode] = useRecoilState(themeModeState)
  const currentPortalAccess = useRecoilValue(currentPortalAccessSelector)

  useEffect(() => {
    if (currentLocale) {
      setUserLanguage(currentLocale)
    }
  }, [currentLocale])

  const handleLogout = (): void => {
    goTo(Path.LOGOUT)
  }

  const handleSelectLanguage = (language: Locale): void => {
    localStorage.setItem('userLanguage', String(language))
    setUserLanguage(language)
    setCurrentLocale(language)
  }

  const handleClickLogo = (): void => {
    goTo(Path.CASES_LIST)
  }

  const handleGoToProfile = (): void => {
    goTo('/profile')
  }

  const handleToggleTheme = useCallback((): void => {
    const newThemeMode =
      themeMode === ThemeMode.LIGHT ? ThemeMode.DARK : ThemeMode.LIGHT
    localStorage.setItem('themeMode', newThemeMode)
    setThemeMode(newThemeMode)
  }, [themeMode])

  return (
    <TopBannerWrapper sx={{ flexGrow: 1 }}>
      <Grid container paddingX={2}>
        <Grid item flexGrow={1} alignSelf={'center'}>
          <Stack>
            <LogoWrapper>
              {portalSetting?.logoUrl && (
                <img
                  src={portalSetting?.logoUrl}
                  onClick={handleClickLogo}
                  alt={portalSetting?.name}
                />
              )}

              {(!portalSetting?.logoUrl ||
                portalSetting.type === PortalType.PROPERTY) &&
                portalSetting?.name}
            </LogoWrapper>
          </Stack>
        </Grid>
        <Grid item textAlign={'right'} alignItems="center" display="flex">
          <Stack direction={'row'} spacing={2} alignItems={'center'}>
            {userAccess?.userData.admin && (
              <UserInfoWrapper
                title={
                  <Stack>
                    <Typography sx={{ fontWeight: 500, fontSize: 14 }}>
                      {userAccess?.userData.firstName}{' '}
                      {userAccess?.userData.lastName}
                    </Typography>
                    <InfoText>{userAccess?.userData.email}</InfoText>
                  </Stack>
                }
              >
                <Avatar
                  src={getThumbnailUrl(userAccess?.userData.avatarUrl)}
                  sx={{ width: 24, height: 24, fontSize: 14 }}
                  alt={`${userAccess?.userData.firstName} ${userAccess?.userData.lastName}`}
                >
                  {nameInitials(
                    `${userAccess?.userData.firstName} ${userAccess?.userData.lastName}`,
                  )}
                </Avatar>
              </UserInfoWrapper>
            )}

            {currentPortalAccess?.memberData && (
              <UserInfoWrapper
                title={
                  <Stack>
                    <Typography sx={{ fontWeight: 500, fontSize: 14 }}>
                      {currentPortalAccess?.memberData.firstName}{' '}
                      {currentPortalAccess?.memberData.lastName}
                    </Typography>
                    <InfoText>{currentPortalAccess?.memberData.email}</InfoText>
                  </Stack>
                }
              >
                <IconButton
                  onClick={handleGoToProfile}
                  aria-label={formatMessage({
                    id: 'my_profile.header',
                  })}
                >
                  <Avatar
                    src={currentPortalAccess?.memberData.avatarUrl ?? ''}
                    sx={{ width: 24, height: 24, fontSize: 14 }}
                    alt={`${currentPortalAccess?.memberData.firstName} ${currentPortalAccess?.memberData.lastName}`}
                  >
                    {nameInitials(
                      `${currentPortalAccess?.memberData.firstName} ${currentPortalAccess?.memberData.lastName}`,
                    )}
                  </Avatar>
                </IconButton>
              </UserInfoWrapper>
            )}

            {portalSetting && portalSetting?.supportedLanguages.length > 1 && (
              <Select
                value={userLanguage}
                size="small"
                inputProps={{
                  'aria-label': formatMessage({
                    id: 'general.select.language',
                  }),
                }}
              >
                {portalSetting?.supportedLanguages.map((language) => (
                  <MenuItem
                    key={language}
                    value={language}
                    onClick={(): void => {
                      handleSelectLanguage(language)
                    }}
                  >
                    {SUPPORT_LOCALE_LABEL[language]}
                  </MenuItem>
                ))}
              </Select>
            )}

            <IconButton
              sx={{ ml: 1 }}
              onClick={handleToggleTheme}
              color="inherit"
              aria-label={formatMessage({
                id:
                  themeMode === ThemeMode.DARK
                    ? 'top_banner.light_theme'
                    : 'top_banner.dark_theme',
              })}
            >
              {themeMode === ThemeMode.DARK ? (
                <LightThemeIcon />
              ) : (
                <DarkThemeIcon />
              )}
            </IconButton>

            <IconButton
              data-testid="logout-button"
              onClick={handleLogout}
              color="inherit"
              aria-label={formatMessage({
                id: 'general.icon_button.logout',
              })}
            >
              <LogoutIcon />
            </IconButton>
          </Stack>
        </Grid>
      </Grid>
    </TopBannerWrapper>
  )
}

export default TopBanner
