import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useIntl } from 'react-intl'
import useSWR from 'swr'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import { useForm, Controller } from 'react-hook-form'
import { useParams, useSearchParams } from 'react-router-dom'
import { useSnackbar } from 'notistack'
import {
  headingsPlugin,
  listsPlugin,
  linkPlugin,
  linkDialogPlugin,
  quotePlugin,
  UndoRedo,
  BoldItalicUnderlineToggles,
  toolbarPlugin,
  BlockTypeSelect,
  ListsToggle,
  CreateLink,
  type MDXEditorMethods,
} from '@mdxeditor/editor'
import '@mdxeditor/editor/style.css'
import LoadingButton from '@mui/lab/LoadingButton'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Stack from '@mui/material/Stack'
import FormControl from '@mui/material/FormControl'
import InputAdornment from '@mui/material/InputAdornment'
import FormHelperText from '@mui/material/FormHelperText'
import InputLabel from '@mui/material/InputLabel'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import OutlinedInput from '@mui/material/OutlinedInput'
import CircularProgress from '@mui/material/CircularProgress'
import Chip from '@mui/material/Chip'
import Checkbox from '@mui/material/Checkbox'
import ListItemText from '@mui/material/ListItemText'
import TextField from '@mui/material/TextField'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import EditIcon from '@mui/icons-material/Edit'
import CottageIcon from '@mui/icons-material/Cottage'
import LanguageIcon from '@mui/icons-material/Language'

import {
  MainHeader,
  BackButtonGrey,
  SettingWrapper,
  MDEditor,
} from 'components/StyledComponents'
import useRoute from 'hooks/useNavigate'
import { type Locale } from 'types'
import { Path, ThemeMode } from '../commonConstants'
import {
  portalSettingState,
  sortedSupportLanguagesSelector,
} from 'state/portalSettingStates'
import {
  hasNewFileState,
  hasUnsavedChangesState,
  isFileUploadingState,
  processedFormFilesState,
} from 'state/formStates'
import TranslationDialog from 'components/TranslationDialog'
import useApi from 'hooks/useApi'
import { type ItemBasic } from 'components/item/itemTypes'
import usePortalSetting from 'hooks/usePortalSetting'
import {
  convertLocalizedStringToData,
  convertDataToLocalizedString,
} from 'utils/stringUtils'
import {
  type NewsPayload,
  type News,
  type NewsFormData,
} from 'components/news/newsTypes'
import {
  NEWS_TITLE_MAX_LENGTH,
  NewsStatus,
} from 'components/news/newsConstants'
import ImageUploader from 'components/form/ImageUploader'
import NewsPreviewDialog from 'components/news/NewsPreviewDialog'
import { themeModeState } from 'state/layoutStates'

const FORM_NAME = 'EDIT_NEWS'
const MAX_IMAGE_WIDTH_IN_PX_FOR_UPLOAD = 500
const MAX_IMAGE_HEIGHT_IN_PX_FOR_UPLOAD = 500

const NewsEditPage: React.FC = () => {
  const { formatMessage } = useIntl()
  const { newsId } = useParams()
  const [searchParams] = useSearchParams()
  const isCopy = searchParams.get('isCopy')
  const { goTo } = useRoute()
  const supportLanguages = useRecoilValue(sortedSupportLanguagesSelector)
  const { sendPostRequest, sendPutRequest } = useApi()
  const { enqueueSnackbar } = useSnackbar()
  const [isSaving, setIsSaving] = useState(false)
  const portalSetting = useRecoilValue(portalSettingState)
  const [isTranslationDialogOpen, setIsTranslationDialogOpen] = useState(false)
  const [isPreviewDialogOpen, setIsPreviewDialogOpen] = useState(false)
  const [translationDialogTitle, setTranslationDialogTitle] = useState('')
  const [translationInputRows, setTranslationInputRows] = useState(3)
  const setHasUnsavedChanges = useSetRecoilState(hasUnsavedChangesState)
  const translationContext = useRef<'titles' | 'bodies' | null>(null)
  const [maxTranslationLength, setMaxTranslationLength] = useState<
    number | undefined
  >(NEWS_TITLE_MAX_LENGTH)
  const [isRichEditor, setIsRichEditor] = useState(false)
  const [items, setItems] = useState<ItemBasic[]>([])
  const [isLoadingItems, setIsLoadingItems] = useState(false)
  const { getLocalizedContent } = usePortalSetting()
  const isFileUploading = useRecoilValue(isFileUploadingState(FORM_NAME))
  const processedFormFiles = useRecoilValue(processedFormFilesState(FORM_NAME))
  const hasNewFile = useRecoilValue(hasNewFileState)
  const [isPublishConfirmDialogOpen, setIsPublishConfirmDialogOpen] =
    useState(false)
  const editorRef = useRef<MDXEditorMethods>(null)
  const themeMode = useRecoilValue(themeModeState)
  const { data: newsData, isLoading } = useSWR<News>(
    portalSetting && newsId
      ? `${process.env.REACT_APP_API_PATH ?? ''}/portals/${
          portalSetting.id
        }/news/${newsId}`
      : null,
  )
  const {
    control,
    setValue,
    formState: { errors, isValid, isDirty, dirtyFields },
    getValues,
    trigger,
    watch,
  } = useForm<NewsFormData>({
    mode: 'onTouched',
  })

  const titles = watch('titles')
  const bodies = watch('bodies')

  useEffect(() => {
    if (newsData) {
      setValue('titles', convertLocalizedStringToData(newsData.titles))
      setValue('bodies', convertLocalizedStringToData(newsData.bodies))
      setValue(
        'itemIds',
        newsData.items.map((item) => item.id),
      )
    }
  }, [newsData])

  useEffect(() => {
    if (bodies && editorRef.current && portalSetting) {
      editorRef.current.setMarkdown(bodies[portalSetting.defaultLanguage])
    }
  }, [bodies, editorRef, portalSetting])

  const getItems = async (): Promise<void> => {
    setIsLoadingItems(true)
    const response = await sendPostRequest(
      `${process.env.REACT_APP_API_PATH ?? ''}/portals/${
        portalSetting?.id
      }/news:listAssignableItems`,
    )
    const itemsData = await response.json()
    setItems(itemsData)
    setIsLoadingItems(false)
  }

  useEffect(() => {
    if (portalSetting) {
      void getItems()
    }
  }, [portalSetting])

  const hasUnsavedChanges = useMemo(
    () => hasNewFile || isDirty,
    [hasNewFile, isDirty],
  )

  useEffect(() => {
    setHasUnsavedChanges(hasUnsavedChanges)
  }, [hasUnsavedChanges])

  const handleGoBack = (): void => {
    goTo(Path.NEWS_LIST)
  }

  const handleOpenTitleTranslationDialog = (): void => {
    setIsTranslationDialogOpen(true)
    setIsRichEditor(false)
    translationContext.current = 'titles'
    setMaxTranslationLength(NEWS_TITLE_MAX_LENGTH)
    setTranslationDialogTitle(
      formatMessage({
        id: 'portal_item_edit.button.manage_localization',
      }),
    )
    setTranslationInputRows(1)
  }

  const handleOpenBodyTranslationDialog = (): void => {
    setIsTranslationDialogOpen(true)
    setIsRichEditor(true)
    setMaxTranslationLength(undefined)
    translationContext.current = 'bodies'
    setTranslationDialogTitle(
      formatMessage({
        id: 'portal_item_edit.button.manage_localization',
      }),
    )
  }

  const handleSaveTranslation = (data: Record<Locale, string>): void => {
    if (translationContext.current) {
      setValue(translationContext.current, data, {
        shouldValidate: true,
        shouldTouch: true,
        shouldDirty: true,
      })
      void trigger(translationContext.current)
    }
    setIsTranslationDialogOpen(false)
  }

  const handleCloseTranslationDialog = (): void => {
    setIsTranslationDialogOpen(false)
  }

  const getTranslationDefaultLanguageValue = useCallback((): Record<
    string,
    string
  > => {
    if (translationContext.current) {
      return getValues(translationContext.current)
    }

    return {}
  }, [translationContext.current])

  const validateTranslations = (name: 'titles' | 'bodies'): null | string => {
    if (!portalSetting) {
      return null
    }

    const data = getValues(name)

    let missingTranslations: Locale[] = [...portalSetting.supportedLanguages]

    if (data) {
      const keys = Object.keys(data)
      keys.forEach((key) => {
        if (data[key]) {
          missingTranslations = missingTranslations.filter(
            (item) => item !== key,
          )
        }
      })

      if (missingTranslations.length) {
        return missingTranslations.join(', ')
      }
    }

    return null
  }

  const missingTitlesTranslation = useMemo(
    () => !!dirtyFields.titles && !!validateTranslations('titles'),
    [titles],
  )

  const shouldDisableSaving = useMemo(
    () =>
      (!isValid ||
        Object.keys(errors).length > 0 ||
        isLoadingItems ||
        isSaving ||
        isFileUploading ||
        missingTitlesTranslation) &&
      !hasNewFile,
    [
      isValid,
      errors,
      isLoadingItems,
      isSaving,
      portalSetting?.type,
      missingTitlesTranslation,
      isFileUploading,
      hasNewFile,
    ],
  )

  const onSave = useCallback(
    async (data: NewsFormData, shouldPublish: boolean): Promise<void> => {
      if (portalSetting) {
        try {
          setIsSaving(true)
          const formData: NewsPayload = {
            itemIds: data.itemIds,
            titles: convertDataToLocalizedString(data.titles),
            bodies: convertDataToLocalizedString(data.bodies),
            publish: shouldPublish,
          }

          if (processedFormFiles.length > 0) {
            formData.url = processedFormFiles[0].url
          }

          if (newsId && !isCopy) {
            await sendPutRequest(
              `${process.env.REACT_APP_API_PATH ?? ''}/portals/${
                portalSetting.id
              }/news/${newsId}`,
              formData,
            )
          } else {
            await sendPostRequest(
              `${process.env.REACT_APP_API_PATH ?? ''}/portals/${
                portalSetting.id
              }/news`,
              formData,
            )
          }

          enqueueSnackbar(formatMessage({ id: 'general.text.changes_saved' }), {
            variant: 'success',
          })

          goTo(Path.NEWS_LIST, true)
        } catch (error) {
          console.error(error)
        } finally {
          setIsSaving(false)
          setHasUnsavedChanges(false)
        }
      }
    },
    [portalSetting, newsId, processedFormFiles],
  )

  const handlePublishNews = (): void => {
    const data = getValues()
    void onSave(data, true)
  }

  const handleSaveAsDraft = (): void => {
    const data = getValues()
    void onSave(data, false)
  }

  const handleSave = useCallback((): void => {
    const data = getValues()
    void onSave(data, newsData?.status === NewsStatus.PUBLISHED)
  }, [newsData, processedFormFiles])

  const handleOpenPreviewDialog = (): void => {
    setIsPreviewDialogOpen(true)
  }

  const handleClosePreviewDialog = (): void => {
    setIsPreviewDialogOpen(false)
  }

  const handleClosePublishConfirmDialog = (): void => {
    setIsPublishConfirmDialogOpen(false)
  }

  const handleOpenPublishConfirmDialog = (): void => {
    setIsPublishConfirmDialogOpen(true)
  }

  if ((newsId && isLoading) || !portalSetting) {
    return <CircularProgress />
  }

  return (
    <>
      <Stack height={'100%'} width={'100%'}>
        <Stack direction="row" width="100%" spacing={2} marginBottom={2}>
          <Stack flexGrow={1} direction="row" spacing={1}>
            <BackButtonGrey
              onClick={handleGoBack}
              size="small"
              aria-label={formatMessage({
                id: 'general.icon_button.go_back',
              })}
            >
              <ArrowBackIcon />
            </BackButtonGrey>

            <MainHeader>
              {newsId && !isCopy && formatMessage({ id: 'news_edit.header' })}
              {newsId && isCopy && formatMessage({ id: 'news_copy.header' })}
              {!newsId && formatMessage({ id: 'news_add.header' })}
            </MainHeader>
          </Stack>

          <Button size="small" onClick={handleOpenPreviewDialog}>
            {formatMessage({ id: 'news_edit.button.preview' })}
          </Button>

          {!isCopy && newsData?._operations.canEdit && (
            <LoadingButton
              variant="contained"
              size="small"
              disabled={shouldDisableSaving}
              loading={isSaving}
              onClick={handleSave}
            >
              {formatMessage({ id: 'general.button.save' })}
            </LoadingButton>
          )}

          {(!newsId || isCopy) && (
            <LoadingButton
              variant="contained"
              size="small"
              disabled={shouldDisableSaving}
              loading={isSaving}
              onClick={handleSaveAsDraft}
            >
              {formatMessage({ id: 'news_edit.button.save_as_draft' })}
            </LoadingButton>
          )}

          {(!newsId || newsData?._operations.canPublish || isCopy) && (
            <LoadingButton
              variant="contained"
              size="small"
              disabled={shouldDisableSaving}
              loading={isSaving}
              onClick={handleOpenPublishConfirmDialog}
            >
              {formatMessage({ id: 'news_edit.button.publish' })}
            </LoadingButton>
          )}
        </Stack>

        <SettingWrapper width="100%" flexGrow={1}>
          <Stack paddingY={2} paddingX={4} spacing={2}>
            {(!newsId || newsData?._operations.canEditItems || isCopy) && (
              <FormControl fullWidth error={!!errors.itemIds}>
                <InputLabel id="items-label" size="small" required>
                  {formatMessage({
                    id: 'comment_template_edit.label.items',
                  })}
                </InputLabel>
                <Controller
                  name="itemIds"
                  control={control}
                  rules={{
                    required: true,
                  }}
                  defaultValue={[]}
                  render={({ field }) => (
                    <Select
                      {...field}
                      required
                      multiple
                      labelId="items-label"
                      label={formatMessage({
                        id: 'comment_template_edit.label.items',
                      })}
                      startAdornment={
                        <InputAdornment position="start">
                          <CottageIcon fontSize="small" color="primary" />
                        </InputAdornment>
                      }
                      input={
                        <OutlinedInput
                          label={formatMessage({
                            id: 'comment_template_edit.label.items',
                          })}
                        />
                      }
                      size="small"
                      fullWidth
                      variant="outlined"
                      renderValue={(selected) => (
                        <Box
                          sx={{
                            display: 'flex',
                            flexWrap: 'wrap',
                            gap: 0.5,
                          }}
                        >
                          {selected.map((value) => {
                            const selectedItem = items?.find(
                              (item) => item.id === value,
                            )

                            return (
                              <Chip
                                key={value}
                                size="small"
                                label={getLocalizedContent(selectedItem?.names)}
                              />
                            )
                          })}
                        </Box>
                      )}
                    >
                      {items?.map((item) => (
                        <MenuItem key={item.id} value={item.id}>
                          <Checkbox
                            checked={getValues('itemIds')?.includes(item.id)}
                          />
                          <ListItemText
                            primary={getLocalizedContent(item.names)}
                          />
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                />
              </FormControl>
            )}

            <Stack width="100%">
              <Box paddingY={2} height={300}>
                <ImageUploader
                  formName={FORM_NAME}
                  maxImageWidth={MAX_IMAGE_WIDTH_IN_PX_FOR_UPLOAD}
                  maxImageHeight={MAX_IMAGE_HEIGHT_IN_PX_FOR_UPLOAD}
                  defaultImageUrl={newsData?.url}
                  hintText={formatMessage({
                    id: 'image_uploader.hint.recommended_ratio_4_3',
                  })}
                />
              </Box>

              {supportLanguages.length > 1 && (
                <Stack direction="row" alignItems="center">
                  <Box flexGrow={1}>
                    <FormHelperText error>
                      {!!dirtyFields.titles &&
                        !!validateTranslations('titles') &&
                        formatMessage(
                          {
                            id: 'portal_item_edit.error.missing_translations',
                          },
                          {
                            missingTranslations: validateTranslations('titles'),
                          },
                        )}
                    </FormHelperText>
                  </Box>
                  <Button
                    size="small"
                    startIcon={<EditIcon />}
                    onClick={handleOpenTitleTranslationDialog}
                  >
                    {formatMessage({
                      id: 'portal_item_edit.button.manage_localization',
                    })}
                  </Button>
                </Stack>
              )}

              <FormControl>
                <Controller
                  name={`titles.${portalSetting.defaultLanguage}`}
                  control={control}
                  defaultValue=""
                  rules={{
                    maxLength: NEWS_TITLE_MAX_LENGTH,
                    required: true,
                  }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      required
                      error={!!errors.titles}
                      size="small"
                      label={`${formatMessage({
                        id: 'comment_template_edit.label.name',
                      })}  ${
                        supportLanguages.length < 2
                          ? ''
                          : ` (${portalSetting.defaultLanguage})`
                      }`}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <LanguageIcon fontSize="small" color="primary" />
                          </InputAdornment>
                        ),
                      }}
                      variant="outlined"
                      fullWidth
                    />
                  )}
                />
              </FormControl>
            </Stack>

            <Stack width="100%">
              {supportLanguages.length > 1 && (
                <Stack direction="row" alignItems="center">
                  <Box flexGrow={1}>
                    <FormHelperText error>
                      {!!dirtyFields.bodies &&
                        !!validateTranslations('bodies') &&
                        formatMessage(
                          {
                            id: 'portal_item_edit.error.missing_translations',
                          },
                          {
                            missingTranslations: validateTranslations('bodies'),
                          },
                        )}
                    </FormHelperText>
                  </Box>
                  <Button
                    size="small"
                    startIcon={<EditIcon />}
                    onClick={handleOpenBodyTranslationDialog}
                  >
                    {formatMessage({
                      id: 'portal_item_edit.button.manage_localization',
                    })}
                  </Button>
                </Stack>
              )}

              <FormControl>
                <Controller
                  name={`bodies.${portalSetting.defaultLanguage}`}
                  control={control}
                  defaultValue=""
                  rules={{
                    required: true,
                  }}
                  render={({ field }) => (
                    <MDEditor
                      ref={editorRef}
                      className={
                        themeMode === ThemeMode.DARK ? 'dark-theme' : ''
                      }
                      markdown=""
                      onChange={field.onChange}
                      plugins={[
                        headingsPlugin(),
                        listsPlugin(),
                        linkPlugin(),
                        linkDialogPlugin(),
                        quotePlugin(),
                        toolbarPlugin({
                          toolbarContents: () => (
                            <>
                              {' '}
                              <UndoRedo />
                              <BlockTypeSelect />
                              <BoldItalicUnderlineToggles />
                              <ListsToggle options={['bullet', 'number']} />
                              <CreateLink />
                            </>
                          ),
                        }),
                      ]}
                    />
                  )}
                />
              </FormControl>
            </Stack>
          </Stack>
        </SettingWrapper>
      </Stack>

      <TranslationDialog
        isOpen={isTranslationDialogOpen}
        title={translationDialogTitle}
        rows={translationInputRows}
        defaultValue={getTranslationDefaultLanguageValue()}
        maxLength={maxTranslationLength}
        onSave={handleSaveTranslation}
        onClose={handleCloseTranslationDialog}
        isRichEditor={isRichEditor}
      />

      <NewsPreviewDialog
        isOpen={isPreviewDialogOpen}
        imageUrl={processedFormFiles?.[0]?.url || newsData?.url}
        onClose={handleClosePreviewDialog}
        titles={titles}
        bodies={bodies}
        published={newsData?.published}
      />

      <Dialog open={isPublishConfirmDialogOpen} maxWidth="xs">
        <DialogTitle sx={{ textAlign: 'center' }}>
          {formatMessage({ id: 'news_edit.confirm.publish.title' })}
        </DialogTitle>
        <DialogContent sx={{ textAlign: 'center' }}>
          {formatMessage({ id: 'news_edit.confirm.publish.content' })}
        </DialogContent>
        <DialogActions>
          <Stack width="100%" spacing={1}>
            <LoadingButton
              loading={isSaving}
              fullWidth
              onClick={handlePublishNews}
              autoFocus
              variant="contained"
              color="error"
            >
              {formatMessage({
                id: 'news_edit.button.publish',
              })}
            </LoadingButton>
            <Button
              fullWidth
              onClick={handleClosePublishConfirmDialog}
              variant="outlined"
              color="error"
            >
              {formatMessage({
                id: 'general.button.cancel',
              })}
            </Button>
          </Stack>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default NewsEditPage
