import Box from '@mui/material/Box'

import SubCategoryListTree from './SubCategoryListTree'
import { type Category } from '../categoryTypes'
import { TreeView } from 'components/category/CategoryStyledComponents'

type CategoryListTreeProps = {
  categories: Category[]
}

const CategoryListTree: React.FC<CategoryListTreeProps> = ({ categories }) => {
  return (
    <TreeView
      slots={{
        collapseIcon: Box,
        expandIcon: Box,
      }}
    >
      <SubCategoryListTree paths={[]} categories={categories} />
    </TreeView>
  )
}

export default CategoryListTree
