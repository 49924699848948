import { useCallback, useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { useRecoilValue, useRecoilState } from 'recoil'
import styled from '@mui/material/styles/styled'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Stack from '@mui/material/Stack'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'
import PostAddIcon from '@mui/icons-material/PostAdd'
import DarkThemeIcon from '@mui/icons-material/Brightness4'
import LightThemeIcon from '@mui/icons-material/Brightness7'

import {
  currentLocaleState,
  publicPortalInfoState,
} from 'state/portalSettingStates'
import { Path, SUPPORT_LOCALE_LABEL, ThemeMode } from '../../commonConstants'
import { type Locale } from 'types'
import useRoute from 'hooks/useNavigate'
import { themeModeState } from 'state/layoutStates'

type PublicTopBannerProps = {
  onAddCase?: () => void
}

const TopBannerWrapper = styled(Box)`
  box-shadow: 0px 4px 8px 0px rgba(35, 45, 66, 0.05);
  background: ${({ theme }) => theme.palette.background.paper};
  height: 66px;
`

const LogoWrapper = styled(Stack)`
  & img {
    cursor: pointer;
  }
`

const PublicTopBanner: React.FC<PublicTopBannerProps> = ({ onAddCase }) => {
  const { formatMessage } = useIntl()
  const { goTo } = useRoute()
  const publicPortalInfo = useRecoilValue(publicPortalInfoState)
  const [userLanguage, setUserLanguage] = useState('')
  const [currentLocale, setCurrentLocale] = useRecoilState(currentLocaleState)
  const [themeMode, setThemeMode] = useRecoilState(themeModeState)

  useEffect(() => {
    if (currentLocale) {
      setUserLanguage(currentLocale)
    }
  }, [currentLocale])

  const handleSelectLanguage = (language: Locale): void => {
    localStorage.setItem('publicUserLanguage', String(language))
    setUserLanguage(language)
    setCurrentLocale(language)
  }

  const handleClickLogo = (): void => {
    goTo(Path.PUBLIC_CASE_LIST)
  }

  const handleToggleTheme = useCallback((): void => {
    const newThemeMode =
      themeMode === ThemeMode.LIGHT ? ThemeMode.DARK : ThemeMode.LIGHT
    localStorage.setItem('themeMode', newThemeMode)
    setThemeMode(newThemeMode)
  }, [themeMode])

  return (
    <TopBannerWrapper>
      <Grid container paddingX={2} paddingY={1}>
        <Grid item flexGrow={1} alignSelf={'center'}>
          <LogoWrapper direction="row" spacing={2} alignItems="center">
            {publicPortalInfo?.logoUrl && (
              <img
                src={publicPortalInfo?.logoUrl}
                height="50px"
                onClick={handleClickLogo}
                alt={publicPortalInfo?.name}
              />
            )}
          </LogoWrapper>
        </Grid>
        <Grid item textAlign={'right'} alignItems="center" display="flex">
          <Stack direction={'row'} spacing={2}>
            {publicPortalInfo &&
              publicPortalInfo.supportedLanguages.length > 1 && (
                <Select
                  value={userLanguage}
                  size="small"
                  inputProps={{
                    'aria-label': formatMessage({
                      id: 'general.select.language',
                    }),
                  }}
                >
                  {publicPortalInfo?.supportedLanguages.map((language) => (
                    <MenuItem
                      key={language}
                      value={language}
                      onClick={(): void => {
                        handleSelectLanguage(language)
                      }}
                    >
                      {SUPPORT_LOCALE_LABEL[language]}
                    </MenuItem>
                  ))}
                </Select>
              )}

            <IconButton
              sx={{ ml: 1 }}
              onClick={handleToggleTheme}
              color="inherit"
              aria-label={formatMessage({
                id:
                  themeMode === ThemeMode.DARK
                    ? 'top_banner.light_theme'
                    : 'top_banner.dark_theme',
              })}
            >
              {themeMode === ThemeMode.DARK ? (
                <LightThemeIcon />
              ) : (
                <DarkThemeIcon />
              )}
            </IconButton>

            {onAddCase && (
              <Button
                variant="contained"
                startIcon={<PostAddIcon />}
                size="small"
                onClick={onAddCase}
                color="secondary"
              >
                {formatMessage({ id: 'consumer_page.button.report' })}
              </Button>
            )}
          </Stack>
        </Grid>
      </Grid>
    </TopBannerWrapper>
  )
}

export default PublicTopBanner
