import { useEffect, useLayoutEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import useSWR, { mutate } from 'swr'
import { useRecoilState, useSetRecoilState } from 'recoil'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'

import PublicTopBanner from 'components/publicCase/PublicTopBanner'
import { type PublicCasesRawData } from 'components/publicCase/publicCaseTypes'
import { Path } from '../commonConstants'
import useRoute from 'hooks/useNavigate'
import PublicFooter from 'components/publicCase/PublicFooter'
import {
  currentLocaleState,
  publicPortalInfoState,
} from 'state/portalSettingStates'
import PublicCaseListMap from 'components/publicCase/PublicCaseListMap'
import PublicCaseCreatedDialog from 'components/publicCase/PublicCaseCreatedDialog'
import PublicAlerts from 'components/publicCase/PublicAlerts'

const PublicCaseListPage: React.FC = () => {
  const { goTo } = useRoute()
  const [searchParams] = useSearchParams()
  const portalId = searchParams.get('portalId')
  const {
    data: casesResponseData,
    isLoading,
    isValidating,
  } = useSWR<PublicCasesRawData>(
    portalId
      ? `${
          process.env.REACT_APP_PUBLIC_API_PATH ?? ''
        }/portals/${portalId}/cases`
      : null,
  )
  const [caseListWidth, setCaseListWidth] = useState(600)
  const [publicPortalInfo, setPublicPortalInfo] = useRecoilState(
    publicPortalInfoState,
  )
  const setCurrentLocale = useSetRecoilState(currentLocaleState)

  useEffect(() => {
    if (casesResponseData && !publicPortalInfo) {
      setPublicPortalInfo(casesResponseData.portal)

      const userLanguage = localStorage.getItem('publicUserLanguage')
      const urlLang = searchParams.get('lang')

      let currentLocale =
        urlLang?.toUpperCase() ?? casesResponseData.portal.defaultLanguage

      if (
        userLanguage &&
        casesResponseData.portal.supportedLanguages.includes(userLanguage)
      ) {
        currentLocale = userLanguage
      }

      setCurrentLocale(currentLocale)
      document.title = casesResponseData.portal.name
    }
  }, [casesResponseData, publicPortalInfo])

  useLayoutEffect(() => {
    const screenWidth = Math.max(
      document.documentElement.clientWidth || 0,
      window.innerWidth || 0,
    )

    if (screenWidth < 1200) {
      setCaseListWidth(400)
    }
  }, [])

  const handleRefresh = (): void => {
    void mutate(
      `${
        process.env.REACT_APP_PUBLIC_API_PATH ?? ''
      }/portals/${portalId}/cases`,
    )
  }

  const handleAddCase = (): void => {
    goTo(Path.PUBLIC_CASE_ADD, true)
  }

  return (
    <Box height={'100vh'} width="100%">
      <Stack
        height={'100%'}
        width="100%"
        overflow="hidden"
        sx={{ position: 'relative' }}
      >
        <PublicAlerts alerts={[]} />

        <PublicTopBanner onAddCase={handleAddCase} />
        <Box flexGrow={1} overflow="auto" padding={2}>
          {casesResponseData?.portal && (
            <PublicCaseListMap
              caseListWidth={caseListWidth}
              isLoading={isLoading || isValidating}
              cases={
                casesResponseData?.cases.sort(
                  (a, b) =>
                    new Date(b.updated).getTime() -
                    new Date(a.updated).getTime(),
                ) ?? []
              }
              onRefresh={handleRefresh}
              region={casesResponseData?.portal.mapConfiguration?.region}
              language={casesResponseData?.portal.defaultLanguage.toLowerCase()}
              zoom={casesResponseData?.portal.mapConfiguration?.zoomLevel}
              center={casesResponseData?.portal.mapConfiguration?.centre}
              onAddCase={handleAddCase}
            />
          )}
        </Box>
        <PublicFooter />
        <PublicCaseCreatedDialog />
      </Stack>
    </Box>
  )
}

export default PublicCaseListPage
