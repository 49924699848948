import { useState, useEffect } from 'react'
import { useIntl } from 'react-intl'
import { useRecoilValue } from 'recoil'
import { useParams } from 'react-router-dom'
import styled from '@mui/material/styles/styled'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import CommentIcon from '@mui/icons-material/Comment'
import Button from '@mui/material/Button'
import Divider from '@mui/material/Divider'
import PublicOutlinedIcon from '@mui/icons-material/PublicOutlined'
import CheckIcon from '@mui/icons-material/Check'
import CommentsDisabledIcon from '@mui/icons-material/CommentsDisabled'
import CommentTemplatesIcon from '@mui/icons-material/AddCommentOutlined'

import { type CaseComment } from 'types'
import CommentInput from 'components/form/CommentInput'
import {
  COMMENT_ATTACHMENTS_MAX_COUNT,
  COMMENT_MAX_LENGTH,
} from '../../commonConstants'
import { FILE_INPUT_ACCEPT_TYPE } from 'utils/fileConstants'
import { SmallInfoText } from 'components/StyledComponents'
import usePortalSetting from 'hooks/usePortalSetting'
import ResourceList from 'components/resource/ResourceList'
import UserAvatar from 'components/user/UserAvatar'
import { shortenUuid } from 'utils/stringUtils'
import { portalSettingState } from 'state/portalSettingStates'
import useApi from 'hooks/useApi'
import { type ProcessedFile } from 'utils/fileTypes'
import TemplateSelectDialog from 'components/commentTemplate/CommentTemplateSelectDialog'
import { type CommentTemplate } from 'components/commentTemplate/commentTemplateTypes'

type CommentListProps = {
  itemId: string
  comments?: CaseComment[]
  isShared?: boolean
  isResolved?: boolean
  isLimited?: boolean
  disabled: boolean
  onResolve?: () => Promise<void>
  onSend?: (newComment: CaseComment) => Promise<void>
  goToLog?: (referenceId: string) => void
}

const Wrapper = styled(Stack)`
  width: 100%;
`

const SenderName = styled(Typography)`
  font-size: 14px;
  font-weight: 500;
`

const ResolvedTextWrapper = styled(Stack)`
  color: ${({ theme }) => theme.palette.primary.light};
`

const CommentsWrapper = styled(Stack)`
  width: 100%;
  background: ${({ theme }) => theme.palette.background.paper};
  border-radius: ${({ theme }) => theme.shape.borderRadius}px;
  white-space: pre-line;

  .REPORTER {
    margin-right: 50px;
    background: ${({ theme }) => theme.palette.info.light};
  }

  .MEMBER {
    margin-left: 50px;
    background: ${({ theme }) => theme.palette.info.dark};
  }
`

const CommentWrapper = styled(Stack)<{ resolved: string }>`
  border-radius: ${({ theme }) => 2 * theme.shape.borderRadius}px;
  background: ${({ resolved }) => (resolved ? '' : '#FFF0F3')};
  margin-left: 50px;
`

const EmptyCommentWrapper = styled(Stack)`
  color: #bbc6f1;
  width: 100%;
  align-items: center;
  gap: ${({ theme }) => theme.spacing(1.5)};
  padding: ${({ theme }) => theme.spacing(2)};
`

const InputWrapper = styled(Box)`
  position: -webkit-sticky;
  position: sticky;
  background: ${({ theme }) => theme.palette.background.paper};
  z-index: 1;
  bottom: 0;
`

const FORM_NAME = 'comments'

const CommentList: React.FC<CommentListProps> = ({
  itemId,
  comments,
  isShared = false,
  isResolved = false,
  isLimited = false,
  disabled = false,
  onResolve,
  onSend,
  goToLog,
}) => {
  const { caseId } = useParams()
  const { formatMessage } = useIntl()
  const { formatDate } = usePortalSetting()
  const portalSetting = useRecoilValue(portalSettingState)
  const { sendGetRequest, sendPostRequest } = useApi()
  const [isSending, setIsSending] = useState(false)
  const [defaultComment, setDefaultComment] = useState('')
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
  const [commentTemplates, setCommentTemplates] = useState<CommentTemplate[]>(
    [],
  )

  const getTemplates = async (): Promise<void> => {
    const response = await sendGetRequest(
      `${process.env.REACT_APP_API_PATH ?? ''}/portals/${
        portalSetting?.id
      }/items/${itemId}/templates/comments`,
    )

    if (response) {
      const itemsData = await response.json()

      if (itemsData) {
        const sortedTemplates = (itemsData as CommentTemplate[]).map(
          (commentTemplate) => ({
            ...commentTemplate,
            templates: commentTemplate.contents.sort((a, b) => {
              if (a.language < b.language) {
                return -1
              }
              if (a.language > b.language) {
                return 1
              }
              return 0
            }),
          }),
        )
        setCommentTemplates(sortedTemplates)
      }
    }
  }

  useEffect(() => {
    void getTemplates()
  }, [])

  const handleSend = async (
    comment: string,
    processedFiles: ProcessedFile[],
  ): Promise<void> => {
    if (portalSetting) {
      try {
        setIsSending(true)

        const response = await sendPostRequest(
          `${process.env.REACT_APP_API_PATH ?? ''}/portals/${
            portalSetting.id
          }/cases/${caseId}/comments`,
          {
            content: comment,
            resources: processedFiles.map((processedFormFile) => ({
              uri: processedFormFile.url,
              name: processedFormFile.file.name,
              format: processedFormFile.format,
            })),
          },
        )

        const responseData = await response.json()

        if (onSend) {
          await onSend(responseData)
        }
      } catch (error) {
        console.error(error)
      } finally {
        setIsSending(false)
      }
    }
  }

  const handleTemplateSelectDialogOpen = (
    event: React.MouseEvent<HTMLButtonElement>,
  ): void => {
    setAnchorEl(event.currentTarget)
  }

  const handleCloseTemplateSelectDialog = (): void => {
    setAnchorEl(null)
  }

  const isTemplateSelectDialogOpen = Boolean(anchorEl)

  const handleSelectTemplate = (content: string): void => {
    handleCloseTemplateSelectDialog()
    setDefaultComment(content)
  }

  return (
    <Wrapper data-testid="comment-list">
      <Stack spacing={2} marginTop={2}>
        {!!comments?.length && (
          <CommentsWrapper spacing={1}>
            {comments?.map((comment) => (
              <CommentWrapper
                key={comment.id}
                resolved={comment.resolved ? 'resolved' : ''}
                className={comment.sender.type}
                paddingX={2}
                paddingY={1.5}
              >
                <Stack direction="row" spacing={1}>
                  <Stack
                    flexGrow={1}
                    direction="row"
                    spacing={1}
                    alignItems="center"
                  >
                    <UserAvatar
                      avatarUrl={comment.sender.avatarUrl ?? ''}
                      size={30}
                      name={comment.sender.displayName}
                    />
                    <SenderName>
                      {comment.sender.displayName}
                      {comment.sender.type === 'REPORTER' &&
                        ` (${formatMessage({
                          id: 'comment_list.label.reporter',
                        })})`}
                      {comment.sender.type === 'MEMBER' &&
                        ` (${formatMessage({
                          id: 'comment_list.label.portal_member',
                        })})`}
                    </SenderName>
                  </Stack>

                  <Stack>
                    <Stack direction="row" spacing={1}>
                      <SmallInfoText>
                        {formatDate(comment.created)}
                      </SmallInfoText>

                      {goToLog && (
                        <>
                          <Divider orientation="vertical" flexItem />
                          <SmallInfoText
                            onClick={() => {
                              goToLog(comment.id)
                            }}
                            sx={{
                              cursor: 'pointer',
                            }}
                          >
                            ID {shortenUuid(comment.id)}
                          </SmallInfoText>
                        </>
                      )}
                    </Stack>
                  </Stack>
                </Stack>

                <Stack marginTop={1} spacing={1}>
                  <ResourceList
                    resources={comment.resources}
                    size={117}
                    max={2}
                  />
                  <Typography variant="body2" sx={{ whiteSpace: 'pre-line' }}>
                    {comment.content}
                  </Typography>
                </Stack>
              </CommentWrapper>
            ))}
          </CommentsWrapper>
        )}

        {!comments?.length && (
          <EmptyCommentWrapper>
            <CommentIcon fontSize="large" />
            {formatMessage({ id: 'comment_list.text.no_comments' })}
          </EmptyCommentWrapper>
        )}

        {!disabled && (
          <Stack
            direction="row"
            paddingY={1.5}
            alignItems="center"
            alignSelf="flex-end"
            width="100%"
            justifyContent="end"
          >
            <Stack flexGrow={1}>
              {isShared && (
                <Stack direction="row" spacing={1}>
                  <PublicOutlinedIcon color="error" fontSize="small" />
                  <Typography variant="body2" color="error">
                    {formatMessage({ id: 'comment_list.text.case_shared' })}
                  </Typography>
                </Stack>
              )}

              {isLimited && (
                <Stack direction="row" spacing={1}>
                  <CommentsDisabledIcon color="error" fontSize="small" />
                  <Typography variant="body2" color="error">
                    {formatMessage({ id: 'comment_list.text.comment_limited' })}
                  </Typography>
                </Stack>
              )}
            </Stack>

            {!isResolved && onResolve && (
              <Button
                size="small"
                variant="outlined"
                startIcon={<CheckIcon />}
                onClick={(): void => {
                  if (onResolve) {
                    void onResolve()
                  }
                }}
              >
                {formatMessage({
                  id: 'case_detail.button.resolve_comment',
                })}
              </Button>
            )}

            {isResolved && (
              <ResolvedTextWrapper direction="row" spacing={1}>
                <CheckIcon fontSize="small" />
                <Typography variant="body2">
                  {formatMessage({
                    id: 'comment_list.label.resolved',
                  })}
                </Typography>
              </ResolvedTextWrapper>
            )}
          </Stack>
        )}

        {!disabled && (
          <InputWrapper>
            <CommentInput
              defaultValue={defaultComment}
              formName={FORM_NAME}
              maxLength={COMMENT_MAX_LENGTH}
              accept={[
                FILE_INPUT_ACCEPT_TYPE.IMAGE,
                FILE_INPUT_ACCEPT_TYPE.DOCUMENT,
                FILE_INPUT_ACCEPT_TYPE.EMAIL,
              ]}
              limit={COMMENT_ATTACHMENTS_MAX_COUNT}
              placeholder={formatMessage({
                id: 'comment_list.label.add_comment',
              })}
              isSending={isSending}
              // eslint-disable-next-line @typescript-eslint/no-misused-promises
              onSend={handleSend}
              extraPlugins={
                commentTemplates.length > 0 && (
                  <Button
                    aria-label={formatMessage({
                      id: 'comment_list.icon_button.add_predefined_comment',
                    })}
                    startIcon={
                      <CommentTemplatesIcon
                        fontSize="inherit"
                        color="primary"
                      />
                    }
                    onClick={handleTemplateSelectDialogOpen}
                    size="small"
                  >
                    {formatMessage({
                      id: 'comment_list.icon_button.add_predefined_comment',
                    })}
                  </Button>
                )
              }
            />
          </InputWrapper>
        )}
      </Stack>

      <TemplateSelectDialog
        anchorEl={anchorEl}
        isOpen={isTemplateSelectDialogOpen}
        itemId={itemId}
        onSelectTemplate={handleSelectTemplate}
        onClose={handleCloseTemplateSelectDialog}
        commentTemplates={commentTemplates}
      />
    </Wrapper>
  )
}

export default CommentList
