import { useCallback, useEffect, useRef, useState } from 'react'
import { useIntl } from 'react-intl'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { DndProvider } from 'react-dnd'
import useSWR from 'swr'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import { nanoid } from 'nanoid'
import { enqueueSnackbar } from 'notistack'
import Stack from '@mui/material/Stack'
import Box from '@mui/material/Box'
import LoadingButton from '@mui/lab/LoadingButton'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import DialogContentText from '@mui/material/DialogContentText'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'

import { BackButtonGrey, MainHeader } from 'components/StyledComponents'
import useApi from 'hooks/useApi'
import { hasUnsavedChangesState } from 'state/formStates'
import { portalSettingState } from 'state/portalSettingStates'
import {
  type CategoriesData,
  type CategoryFormData,
} from 'components/categoryV2/categoryTypes'
import useRoute from 'hooks/useNavigate'
import TranslationDialog from 'components/TranslationDialog'
import { type Locale, type LocalizedString } from 'types'
import { CATEGORY_NAME_MAX_LENGTH, Path } from 'commonConstants'
import ErrorIcon from 'assets/icons/error_icon.svg'
import {
  convertTranslationKeysToUpperCase,
  cleanCategoryFormData,
} from 'utils/categoryUtils'
import CategoryEditTree from 'components/categoryV2/edit/CategoryEditTree'
import {
  convertDataToLocalizedString,
  convertLocalizedStringToData,
} from 'utils/stringUtils'

const CategoryEditPage: React.FC = () => {
  const { formatMessage } = useIntl()
  const { goTo } = useRoute()
  const { sendPutRequest } = useApi()
  const portalSetting = useRecoilValue(portalSettingState)
  const [isTranslationDialogOpen, setIsTranslationDialogOpen] = useState(false)
  const [categories, setCategories] = useState<CategoryFormData[]>([])
  const { data: categoriesData } = useSWR<CategoriesData>(
    portalSetting
      ? `${process.env.REACT_APP_API_BASE_PATH ?? ''}/v2/portals/${
          portalSetting.id
        }/categories/root`
      : null,
  )
  const [mode, setMode] = useState<'edit' | 'add'>('add')
  const [currentTranslation, setCurrentTranslation] = useState<
    Record<Locale, string>
  >({})
  const currentPathsRef = useRef<string[]>([])
  const setHasUnsavedChanges = useSetRecoilState(hasUnsavedChangesState)
  const [isSaving, setIsSaving] = useState(false)
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false)

  useEffect(() => {
    if (categoriesData) {
      setCategories(categoriesData.categories)
    }
  }, [categoriesData])

  const handleSave = async (): Promise<void> => {
    if (!portalSetting) {
      return
    }
    try {
      setIsSaving(true)

      await sendPutRequest(
        `${process.env.REACT_APP_API_BASE_PATH ?? ''}/v2/portals/${
          portalSetting.id
        }/categories/root`,
        { categories: cleanCategoryFormData(categories) },
      )

      enqueueSnackbar(
        formatMessage({ id: 'edit_categories.message.updated' }),
        {
          variant: 'success',
        },
      )
    } catch (error) {
      console.error(error)
    } finally {
      setIsSaving(false)
      setHasUnsavedChanges(false)
    }
  }

  const handleGoBack = (): void => {
    goTo(Path.CATEGORIES_V2_LIST)
  }

  const handleAddCategory = (paths: string[]): void => {
    setIsTranslationDialogOpen(true)
    setCurrentTranslation({})
    setMode('add')
    currentPathsRef.current = paths
  }

  const handleSaveTranslation = useCallback(
    (data: Record<Locale, string>): void => {
      setIsTranslationDialogOpen(false)
      const names = convertDataToLocalizedString(data)

      const addCategoryTranslations = (
        categories: CategoryFormData[],
        paths: string[],
      ): CategoryFormData[] => {
        if (paths.length === 0) {
          return [
            ...categories.map((category, index) => ({
              ...category,
              index: index + 1,
            })),
            {
              id: `dummy-${nanoid(6)}`,
              names,
              active: true,
              index: categories.length + 1,
              subcategories: [],
            },
          ]
        }

        const currentId = paths.shift()

        return categories.map((category) => {
          if (category.id === currentId) {
            category.subcategories = addCategoryTranslations(
              category.subcategories ?? [],
              paths,
            )
          }

          return category
        })
      }

      const updateCategoryTranslations = (
        categories: CategoryFormData[],
        paths: string[],
      ): CategoryFormData[] => {
        const currentId = paths.shift()

        return categories.map((category) => {
          if (!category) {
            return category
          }
          if (category.id === currentId) {
            if (paths.length === 0) {
              return {
                ...category,
                names,
              }
            } else {
              category.subcategories = updateCategoryTranslations(
                category.subcategories || [],
                paths,
              )
            }
          }
          return category
        })
      }

      const newData =
        mode === 'add'
          ? addCategoryTranslations([...categories], currentPathsRef.current)
          : updateCategoryTranslations([...categories], currentPathsRef.current)

      setCategories(newData)
      setHasUnsavedChanges(true)
    },
    [mode, categories, currentPathsRef.current],
  )

  const handleDeleteCategory = (paths: string[]): void => {
    currentPathsRef.current = paths
    setIsConfirmDialogOpen(true)
  }

  const handleEditCategory = (
    paths: string[],
    translations: LocalizedString[],
  ): void => {
    currentPathsRef.current = paths
    setCurrentTranslation(
      convertTranslationKeysToUpperCase(
        convertLocalizedStringToData(translations),
      ),
    )
    setIsTranslationDialogOpen(true)
    setMode('edit')
  }

  const handleCloseTranslationDialog = (): void => {
    setIsTranslationDialogOpen(false)
  }

  const handleCloseConfirmDialog = (): void => {
    setIsConfirmDialogOpen(false)
  }

  const handleConfirmedDeleteCategory = (): void => {
    if (currentPathsRef.current.length) {
      const deleteCategory = (
        categories: CategoryFormData[],
        paths: string[],
      ): CategoryFormData[] => {
        const currentId = paths.shift()

        return categories
          .map((category) => {
            if (!category) {
              return category
            }
            if (category.id === currentId) {
              if (paths.length === 0) {
                return undefined
              } else {
                category.subcategories = deleteCategory(
                  category.subcategories || [],
                  paths,
                )
              }
            }
            return category
          })
          .filter((category) => category !== undefined)
      }

      const newData = deleteCategory([...categories], currentPathsRef.current)
      setCategories(newData)
      setHasUnsavedChanges(true)
    }

    setIsConfirmDialogOpen(false)
  }

  const findCategories = (
    paths: string[],
    categories: CategoryFormData[],
  ): CategoryFormData[] => {
    if (paths.length === 0) {
      return categories
    }

    const currentId = paths.shift()

    const category = categories.find((category) => category.id === currentId)

    if (!category) {
      return []
    }

    return findCategories([...paths], category.subcategories ?? [])
  }

  const handleMoveCategory = useCallback(
    (paths: string[], from: number, to: number): void => {
      console.log(paths, from, to)
      const clone = [...categories]
      const targetCategories = findCategories([...paths], clone)
      const [removed] = targetCategories.splice(from, 1)
      targetCategories.splice(to, 0, removed)
      targetCategories.forEach((category, index) => {
        category.index = index + 1
      })

      setCategories(clone)
      setHasUnsavedChanges(true)
    },
    [categories],
  )

  return (
    <DndProvider backend={HTML5Backend}>
      <Stack height="100%" width="100%">
        <Stack direction="row" width="100%" spacing={2} marginBottom={2}>
          <Stack flexGrow={1} direction="row" spacing={1}>
            <BackButtonGrey
              onClick={handleGoBack}
              size="small"
              aria-label={formatMessage({
                id: 'general.icon_button.go_back',
              })}
            >
              <ArrowBackIcon />
            </BackButtonGrey>

            <MainHeader>
              {formatMessage({
                id: 'edit_categories.button.edit_categories',
              })}
            </MainHeader>
          </Stack>

          <LoadingButton
            variant="contained"
            size="small"
            loading={isSaving}
            onClick={() => {
              void handleSave()
            }}
            color="secondary"
          >
            {formatMessage({ id: 'general.button.save' })}
          </LoadingButton>
        </Stack>

        <Stack height="100%" flexGrow={1} overflow={'auto'}>
          <CategoryEditTree
            categories={categories}
            onAddCategory={handleAddCategory}
            onEditCategory={handleEditCategory}
            onDeleteCategory={handleDeleteCategory}
            onMoveCategory={handleMoveCategory}
          />
        </Stack>

        <TranslationDialog
          isOpen={isTranslationDialogOpen}
          required
          title={formatMessage({
            id:
              mode === 'add'
                ? 'edit_categories.title.add_category'
                : 'edit_categories.title.edit_category',
          })}
          rows={1}
          defaultValue={currentTranslation}
          maxLength={CATEGORY_NAME_MAX_LENGTH}
          onSave={handleSaveTranslation}
          onClose={handleCloseTranslationDialog}
        />

        <Dialog open={isConfirmDialogOpen} maxWidth="xs">
          <DialogTitle textAlign="center">
            <Stack alignItems="center" spacing={2}>
              <ErrorIcon />
              <Box textAlign="center">
                {formatMessage({ id: 'edit_categories.delete_confirm.title' })}
              </Box>
            </Stack>
          </DialogTitle>
          <DialogContent>
            <DialogContentText textAlign="center">
              {formatMessage({ id: 'edit_categories.delete_confirm.message' })}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Stack spacing={1} width="100%">
              <Button
                onClick={handleConfirmedDeleteCategory}
                variant="contained"
                fullWidth
                color="error"
              >
                {formatMessage({
                  id: 'edit_categories.delete_confirm.button.delete_category',
                })}
              </Button>
              <Button
                onClick={handleCloseConfirmDialog}
                variant="outlined"
                fullWidth
                color="error"
                autoFocus
              >
                {formatMessage({
                  id: 'general.button.cancel',
                })}
              </Button>
            </Stack>
          </DialogActions>
        </Dialog>
      </Stack>
    </DndProvider>
  )
}

export default CategoryEditPage
