import { useCallback, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useIntl } from 'react-intl'
import { useRecoilValue } from 'recoil'
import { useSnackbar } from 'notistack'
import CircularProgress from '@mui/material/CircularProgress'
import styled from '@mui/material/styles/styled'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import LoadingButton from '@mui/lab/LoadingButton'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import ListItemText from '@mui/material/ListItemText'
import ListItemIcon from '@mui/material/ListItemIcon'
import Avatar from '@mui/material/Avatar'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import usePortalSetting from 'hooks/usePortalSetting'
import DialogContentText from '@mui/material/DialogContentText'
import Tooltip from '@mui/material/Tooltip'
import EditIcon from '@mui/icons-material/Edit'
import AddIcon from '@mui/icons-material/Add'
import UpdateIcon from '@mui/icons-material/Update'
import EventIcon from '@mui/icons-material/Event'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt'
import VideocamIcon from '@mui/icons-material/Videocam'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import BorderColorIcon from '@mui/icons-material/BorderColor'

import TaskEditForm, {
  type TaskCreationFormData,
} from 'components/task/TaskEditForm'
import EmptyTaskIcon from 'assets/icons/empty_task.svg'
import {
  type NewCaseTask,
  type CaseTask,
  type TaskAssignmentCandidates,
  type TaskNote,
} from 'components/task/taskTypes'
import {
  SmallInfoText,
  SubHeader,
  SubSubHeader,
} from 'components/StyledComponents'
import TaskStatusButton from 'components/task/TaskStatusButton'
import { TaskEditingMode, Path } from '../../commonConstants'
import { CaseTaskStatus } from 'components/case/caseConstants'
import useRoute from 'hooks/useNavigate'
import useApi from 'hooks/useApi'
import { portalSettingState } from 'state/portalSettingStates'
import ErrorIcon from 'assets/icons/error_icon.svg'
import AssignmentTurnedInIcon from 'assets/icons/claim_task.svg'
import AssignmentReturnIcon from 'assets/icons/unclaim_task.svg'
import { Divider } from '@mui/material'
import { shortenUuid, nameInitials } from 'utils/stringUtils'
import { TaskAssigneeType } from 'components/task/taskConstants'
import { getThumbnailUrl } from 'utils/fileUtils'
import TaskNoteList from 'components/task/TaskNoteList'

const TaskWrapper = styled(Stack)<{
  status?: CaseTaskStatus
}>`
  border-radius: ${({ theme }) => 2 * theme.shape.borderRadius}px;
  border: 1px solid
    ${({ theme, status }) =>
      status !== CaseTaskStatus.UNASSIGNED
        ? theme.palette.divider
        : theme.palette.error.main};
  margin: 4px;

  :hover {
    border-color: ${({ theme }) => theme.palette.primary.main};
    box-shadow: 0px 0px 0px 3px #bbc6f1;
  }
`

type TaskListProps = {
  itemId: string
  tasks?: CaseTask[]
  statusTransitionsMap?: Map<string, CaseTaskStatus[]>
  isLoading: boolean
  disabled: boolean
  onSave: (newTask: CaseTask) => void
  onUpdateTaskCaches: (
    newTask: CaseTask,
    allowedStatuses?: CaseTaskStatus[],
  ) => void
  goToLog: (referenceId: string) => void
  onReloadTasks: () => void
}

const TaskList: React.FC<TaskListProps> = ({
  itemId,
  tasks,
  statusTransitionsMap,
  isLoading,
  disabled,
  onSave,
  onUpdateTaskCaches,
  goToLog,
  onReloadTasks,
}) => {
  const { formatMessage } = useIntl()
  const { caseId } = useParams()
  const { goTo } = useRoute()
  const [menuEl, setMenuEl] = useState<null | HTMLElement>(null)
  const openMenu = Boolean(menuEl)
  const [reassignedMenuEl, setReassignedMenuEl] = useState<null | HTMLElement>(
    null,
  )
  const openReassignedMenuMenu = Boolean(reassignedMenuEl)
  const [isOpenForm, setIsOpenForm] = useState(false)
  const [selectedTask, setSelectedTask] = useState<CaseTask | null>()
  const [taskEditingMode, setTaskEditingMode] =
    useState<TaskEditingMode | null>(null)
  const { sendPostRequest, sendPutRequest } = useApi()
  const portalSetting = useRecoilValue(portalSettingState)
  const [isSaving, setIsSaving] = useState(false)
  const [updateClaiming, setUpdateClaiming] = useState<string | null>(null)
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false)
  const { enqueueSnackbar } = useSnackbar()
  const { formatDate } = usePortalSetting()
  const [taskAssignmentCandidates, setTaskAssignmentCandidates] =
    useState<TaskAssignmentCandidates | null>(null)
  const [openNotes, setOpenNotes] = useState<Set<string>>(new Set())

  const handleOpenMenu = (
    event: React.MouseEvent<HTMLElement>,
    task: CaseTask,
  ): void => {
    setMenuEl(event.currentTarget)
    setSelectedTask(task)
  }

  const handleCloseMenu = (): void => {
    setMenuEl(null)
  }

  const handleAddTask = (): void => {
    setTaskEditingMode(TaskEditingMode.ADDING)
    setSelectedTask(null)
    setIsOpenForm(true)
  }

  const handleCloseTaskForm = (): void => {
    setIsOpenForm(false)
    setTaskEditingMode(null)
    setSelectedTask(null)
  }

  const handleEditTask = (): void => {
    handleCloseMenu()
    setTaskEditingMode(TaskEditingMode.EDITING)
    setIsOpenForm(true)
  }

  const handleCopyTask = (): void => {
    handleCloseMenu()
    setTaskEditingMode(TaskEditingMode.COPYING)
    setIsOpenForm(true)
  }

  const handleClickDeadline = (task: CaseTask): void => {
    setSelectedTask(task)
    setTaskEditingMode(TaskEditingMode.EDITING)
    setIsOpenForm(true)
  }

  const handleClickAssign = (task: CaseTask): void => {
    setSelectedTask(task)
    setTaskEditingMode(TaskEditingMode.ASSIGNING)
    setIsOpenForm(true)
  }

  const handleVideoCall = useCallback(
    (actionId?: string | null): void => {
      if (caseId) {
        goTo(`${Path.VIDEO_CALL_TASK}/${actionId}`)
      }
    },
    [caseId],
  )

  const handleSaveTask = useCallback(
    async (data: TaskCreationFormData): Promise<void> => {
      if (portalSetting && caseId) {
        try {
          setIsSaving(true)
          const {
            description,
            assigneeMemberId,
            assigneeTeamId,
            assigneeType,
            deadline,
          } = data
          console.log(data)
          const payload: Partial<NewCaseTask> = {
            description,
          }

          if (deadline) {
            payload.deadline = deadline.format()
          }

          if (assigneeType === TaskAssigneeType.MEMBER && assigneeMemberId) {
            payload.assignment = {
              assigneeId: assigneeMemberId,
              assigneeType,
            }
          } else if (assigneeType === TaskAssigneeType.TEAM && assigneeTeamId) {
            payload.assignment = {
              assigneeId: assigneeTeamId,
              assigneeType,
              referenceId: assigneeMemberId || null,
            }
          }

          let responseData = null
          if (selectedTask && taskEditingMode === TaskEditingMode.EDITING) {
            const response = await sendPutRequest(
              `${process.env.REACT_APP_API_PATH ?? ''}/portals/${
                portalSetting.id
              }/cases/${caseId}/tasks/${selectedTask.id}`,
              payload,
            )

            responseData = await response.json()
          } else if (
            selectedTask &&
            taskEditingMode === TaskEditingMode.ASSIGNING
          ) {
            const response = await sendPostRequest(
              `${process.env.REACT_APP_API_PATH ?? ''}/portals/${
                portalSetting.id
              }/cases/${caseId}/tasks/${selectedTask.id}/assignment`,
              {
                ...payload.assignment,
              },
            )

            responseData = await response.json()
            responseData.status = CaseTaskStatus.ASSIGNED
          } else {
            const response = await sendPostRequest(
              `${process.env.REACT_APP_API_PATH ?? ''}/portals/${
                portalSetting.id
              }/cases/${caseId}/tasks`,
              payload,
            )

            responseData = await response.json()
          }

          enqueueSnackbar(formatMessage({ id: 'general.text.changes_saved' }), {
            variant: 'success',
          })

          setIsOpenForm(false)
          onSave(responseData)
        } catch (error) {
          console.error(error)
        } finally {
          setIsSaving(false)
        }
      }
    },
    [portalSetting, caseId, selectedTask, taskEditingMode],
  )

  const handleCloseConfirmDialog = (): void => {
    setIsConfirmDialogOpen(false)
  }

  const handleConfirmedCancelTask = useCallback((): void => {
    if (selectedTask) {
      void updateStatus(selectedTask, CaseTaskStatus.CANCELLED)
    }

    setIsConfirmDialogOpen(false)
  }, [selectedTask])

  const updateStatus = useCallback(
    async (task: CaseTask, newStatus: CaseTaskStatus): Promise<void> => {
      if (portalSetting) {
        try {
          setIsSaving(true)
          const response = await sendPostRequest(
            `${process.env.REACT_APP_API_PATH ?? ''}/portals/${
              portalSetting.id
            }/cases/${caseId}/tasks/${task.id}:transition?to=${newStatus}`,
          )

          const responseData = await response.json()
          if (responseData) {
            onUpdateTaskCaches(
              {
                ...task,
                status: newStatus,
              },
              responseData.allowedStatusTransitions,
            )
            enqueueSnackbar(
              formatMessage({ id: 'general.text.changes_saved' }),
              {
                variant: 'success',
              },
            )
          }
        } catch (error) {
          console.error(error)
        } finally {
          setIsSaving(false)
        }
      }
    },
    [portalSetting, caseId],
  )

  const handleUpdateStatus = (
    task: CaseTask,
    newStatus: CaseTaskStatus,
  ): void => {
    setSelectedTask(task)
    if (newStatus === CaseTaskStatus.CANCELLED) {
      setIsConfirmDialogOpen(true)
    } else {
      void updateStatus(task, newStatus)
    }
  }

  const handleUpdateMyClaim = useCallback(
    async (task: CaseTask, resolution: 'claim' | 'unclaim'): Promise<void> => {
      if (portalSetting) {
        try {
          setUpdateClaiming(task.id)
          const response = await sendPostRequest(
            `${process.env.REACT_APP_API_PATH ?? ''}/portals/${
              portalSetting.id
            }/cases/${caseId}/tasks/${task.id}:${resolution}`,
          )

          const responseData = await response.json()
          if (responseData) {
            onUpdateTaskCaches({
              ...task,
              assignment: responseData,
            })
            enqueueSnackbar(
              formatMessage({ id: 'general.text.changes_saved' }),
              {
                variant: 'success',
              },
            )
          }
        } catch (error) {
          console.error(error)
        } finally {
          setUpdateClaiming(null)
        }
      }
    },
    [portalSetting, caseId],
  )

  const handleOpenReassignedMenu = async (
    event: React.MouseEvent<HTMLElement>,
    task: CaseTask,
  ): Promise<void> => {
    setReassignedMenuEl(event.currentTarget)
    if (portalSetting) {
      const response = await sendPostRequest(
        `${process.env.REACT_APP_API_PATH ?? ''}/portals/${
          portalSetting.id
        }/cases/${caseId}/tasks/${task.id}:listAssignmentCandidates`,
      )
      const responseData = await response.json()
      if (responseData) {
        setTaskAssignmentCandidates(responseData as TaskAssignmentCandidates)
        setSelectedTask(task)
      }
    }
  }

  const handleCloseReassignedMenu = (): void => {
    setReassignedMenuEl(null)
    setSelectedTask(null)
  }

  const handleReassignMember = useCallback(
    async (referenceId: string | null): Promise<void> => {
      if (portalSetting && selectedTask) {
        handleCloseReassignedMenu()
        try {
          const response = await sendPutRequest(
            `${process.env.REACT_APP_API_PATH ?? ''}/portals/${
              portalSetting.id
            }/cases/${caseId}/tasks/${selectedTask.id}/assignment`,
            {
              assigneeId: selectedTask.assignment?.assignmentSummary?.partyId,
              referenceId,
            },
          )
          const responseData = await response.json()
          if (responseData) {
            onUpdateTaskCaches({
              ...selectedTask,
              assignment: responseData,
            })
            enqueueSnackbar(
              formatMessage({ id: 'general.text.changes_saved' }),
              {
                variant: 'success',
              },
            )
          }
        } catch (error) {
          console.error(error)
        }
      }
    },
    [portalSetting, selectedTask, caseId],
  )

  const handleSend = async (newNote: TaskNote): Promise<void> => {
    onReloadTasks()
  }

  return (
    <Stack height={'100%'} spacing={1}>
      {!isOpenForm && (
        <>
          <Stack direction={'row'}>
            <SubHeader>
              {formatMessage({ id: 'case_detail.actions' })}
            </SubHeader>

            {tasks && tasks?.length > 0 && !disabled && (
              <Button
                variant="contained"
                size="small"
                startIcon={<AddIcon />}
                onClick={handleAddTask}
              >
                {formatMessage({
                  id: 'case_detail.actions.button.add_action',
                })}
              </Button>
            )}
          </Stack>

          {!tasks?.length && !disabled && (
            <TaskWrapper
              textAlign="center"
              spacing={2}
              alignItems="center"
              justifyContent="center"
              height={'100%'}
            >
              {isLoading && <CircularProgress />}

              {!isLoading && tasks?.length === 0 && (
                <>
                  <EmptyTaskIcon />
                  <Box width="100%">
                    <SubHeader>
                      {formatMessage({
                        id: 'action_list.label.start_creating_action',
                      })}
                    </SubHeader>
                  </Box>
                  <Box width="100%">
                    <Button
                      variant="contained"
                      size="small"
                      startIcon={<AddIcon />}
                      onClick={handleAddTask}
                    >
                      {formatMessage({
                        id: 'case_detail.actions.button.add_action',
                      })}
                    </Button>
                  </Box>
                </>
              )}
            </TaskWrapper>
          )}
        </>
      )}

      {isOpenForm && (
        <TaskEditForm
          onClose={handleCloseTaskForm}
          onSave={handleSaveTask}
          isSaving={isSaving}
          selectedTask={selectedTask}
          taskEditingMode={taskEditingMode}
          itemId={itemId}
        />
      )}

      <Stack flexGrow={1} overflow="auto" spacing={1} sx={{ padding: '3px' }}>
        {!isOpenForm &&
          tasks?.map((task) => (
            <TaskWrapper
              key={task.id}
              spacing={1}
              padding={1}
              status={task.status}
            >
              <Stack>
                <Stack direction="row">
                  <Stack
                    direction="row"
                    spacing={2}
                    alignItems="center"
                    width="100%"
                    marginBottom={1}
                  >
                    <Box flexGrow={1}>
                      {task.status === CaseTaskStatus.UNASSIGNED &&
                        !disabled &&
                        !task.assignment && (
                          <Button
                            variant="contained"
                            size="small"
                            startIcon={<PersonAddAltIcon />}
                            onClick={() => {
                              handleClickAssign(task)
                            }}
                          >
                            {formatMessage({
                              id: 'action_list.button.assign',
                            })}
                          </Button>
                        )}

                      {task.assignment && (
                        <Stack direction="row" spacing={1} alignItems="center">
                          <Avatar
                            alt={
                              task.assignment.assignmentSummary?.assigneeName ??
                              task.assignment.assignmentSummary?.partyName ??
                              ''
                            }
                            src={getThumbnailUrl(
                              task.assignment.assignmentSummary
                                ?.assigneeAvatarUrl ||
                                task.assignment.assignmentSummary
                                  ?.partyAvatarUrl,
                            )}
                            sx={{ width: 24, height: 24 }}
                          />

                          <Stack>
                            <Typography variant="body2">
                              {task.assignment.assignmentSummary?.partyName ??
                                task.assignment.assignmentSummary?.assigneeName}
                            </Typography>

                            {task.assignment.assignmentSummary?.partyName &&
                              task.assignment.postAssignmentOptions
                                ?.assignable && (
                                <Button
                                  size="small"
                                  onClick={(e) => {
                                    e.stopPropagation()
                                    setTaskAssignmentCandidates(null)
                                    void handleOpenReassignedMenu(e, task)
                                  }}
                                  endIcon={
                                    openReassignedMenuMenu ? (
                                      <KeyboardArrowUpIcon />
                                    ) : (
                                      <KeyboardArrowDownIcon />
                                    )
                                  }
                                >
                                  {task.assignment.assignmentSummary
                                    ?.assigneeName ??
                                    formatMessage({
                                      id: 'task_edit_form.option.no_assignee',
                                    })}
                                </Button>
                              )}

                            {task.assignment.assignmentSummary?.partyName &&
                              !task.assignment.postAssignmentOptions
                                ?.assignable && (
                                <Typography variant="caption">
                                  {task.assignment.assignmentSummary
                                    ?.assigneeName ??
                                    formatMessage({
                                      id: 'task_edit_form.option.no_assignee',
                                    })}
                                </Typography>
                              )}
                          </Stack>

                          {!!task.assignment.assignmentSummary
                            ?.assigneeUserId &&
                            task.status !== CaseTaskStatus.CANCELLED &&
                            !disabled &&
                            false && (
                              <IconButton
                                onClick={() => {
                                  handleVideoCall(task.id)
                                }}
                                size="small"
                                aria-label={formatMessage({
                                  id: 'video_call.header',
                                })}
                              >
                                <VideocamIcon
                                  fontSize="small"
                                  color="secondary"
                                />
                              </IconButton>
                            )}
                        </Stack>
                      )}
                    </Box>

                    <Stack direction="row" spacing={1}>
                      <SmallInfoText>
                        <UpdateIcon sx={{ fontSize: 14 }} />
                        {formatDate(task.updated)}
                      </SmallInfoText>

                      {task.deadline && (
                        <>
                          <Divider orientation="vertical" flexItem />

                          <SmallInfoText>
                            <EventIcon sx={{ fontSize: 14 }} />{' '}
                            {formatDate(task.deadline, true)}
                          </SmallInfoText>
                        </>
                      )}

                      <SmallInfoText
                        onClick={() => {
                          goToLog(task.id)
                        }}
                        sx={{
                          cursor: 'pointer',
                        }}
                      >
                        ID {shortenUuid(task.id)}
                      </SmallInfoText>

                      {!disabled && (
                        <IconButton
                          onClick={(event): void => {
                            handleOpenMenu(event, task)
                          }}
                          size="small"
                          aria-label={formatMessage({
                            id: 'general.icon_button.see_more',
                          })}
                        >
                          <MoreVertIcon
                            sx={{
                              fontSize: 16,
                            }}
                          />
                        </IconButton>
                      )}
                    </Stack>

                    <Stack direction="row" justifyContent="right" spacing={1}>
                      {!disabled &&
                        !task.deadline &&
                        task.status !== CaseTaskStatus.CANCELLED && (
                          <Tooltip
                            title={formatMessage({
                              id: 'action_list.button.set_deadline',
                            })}
                            arrow
                          >
                            <Button
                              variant="contained"
                              color="secondary"
                              size="small"
                              onClick={() => {
                                handleClickDeadline(task)
                              }}
                              sx={{
                                minWidth: 0,
                              }}
                            >
                              <EventIcon sx={{ fontSize: 18 }} />
                            </Button>
                          </Tooltip>
                        )}

                      {task.assignment?.postAssignmentOptions?.claimable && (
                        <Tooltip
                          title={formatMessage({
                            id: 'action_list.button.take_task',
                          })}
                          arrow
                        >
                          <LoadingButton
                            disabled={disabled || updateClaiming === task.id}
                            onClick={(): void => {
                              void handleUpdateMyClaim(task, 'claim')
                            }}
                            size="small"
                            aria-label={formatMessage({
                              id: 'action_list.button.take_task',
                            })}
                            loading={updateClaiming === task.id}
                            variant="contained"
                            color="success"
                            sx={{
                              minWidth: 0,
                              background: '#7ADCD0',
                              ':hover': {
                                background: '#7ADCD0',
                              },
                            }}
                          >
                            <AssignmentTurnedInIcon color="error" />
                          </LoadingButton>
                        </Tooltip>
                      )}

                      {task.assignment?.postAssignmentOptions?.unclaimable && (
                        <Tooltip
                          title={formatMessage({
                            id: 'action_list.button.send_task_back_team',
                          })}
                          arrow
                        >
                          <LoadingButton
                            disabled={disabled || updateClaiming === task.id}
                            onClick={(): void => {
                              void handleUpdateMyClaim(task, 'unclaim')
                            }}
                            size="small"
                            aria-label={formatMessage({
                              id: 'action_list.button.send_task_back_team',
                            })}
                            loading={updateClaiming === task.id}
                            variant="contained"
                            color="error"
                            sx={{
                              minWidth: 0,
                              background: '#FFF0F3',
                              ':hover': {
                                background: '#FFF0F3',
                              },
                            }}
                          >
                            <AssignmentReturnIcon color="error" />
                          </LoadingButton>
                        </Tooltip>
                      )}

                      <TaskStatusButton
                        disabled={disabled}
                        status={task.status}
                        loading={isSaving && selectedTask?.id === task.id}
                        transitions={statusTransitionsMap?.get(task.id)}
                        onUpdateStatus={(newStatus): void => {
                          handleUpdateStatus(task, newStatus)
                        }}
                      />
                    </Stack>
                  </Stack>
                </Stack>

                <Typography variant="body2" sx={{ whiteSpace: 'pre-line' }}>
                  {task.description}
                </Typography>

                {!openNotes.has(task.id) && (
                  <Box textAlign="right">
                    <Button
                      variant="text"
                      size="small"
                      endIcon={<KeyboardArrowDownIcon />}
                      onClick={(): void => {
                        setOpenNotes((prev) => {
                          prev.add(task.id)
                          return new Set(prev)
                        })
                      }}
                    >
                      {formatMessage({
                        id: 'task_list.button.show_notes',
                      })}{' '}
                      {task.notes.length > 0 && `(${task.notes.length})`}
                    </Button>
                  </Box>
                )}

                {openNotes.has(task.id) && (
                  <Box textAlign="right">
                    <Button
                      variant="text"
                      size="small"
                      endIcon={<KeyboardArrowUpIcon />}
                      onClick={(): void => {
                        setOpenNotes((prev) => {
                          prev.delete(task.id)
                          return new Set(prev)
                        })
                      }}
                    >
                      {formatMessage({
                        id: 'task_list.button.hide_notes',
                      })}
                    </Button>
                  </Box>
                )}

                <Box hidden={!openNotes.has(task.id)}>
                  <Stack spacing={2}>
                    <Divider />
                    <Stack direction="row" spacing={1} alignItems="center">
                      <BorderColorIcon color="inherit" fontSize="small" />
                      <SubSubHeader>
                        {formatMessage({
                          id: 'task_list.label.updates',
                        })}
                      </SubSubHeader>
                    </Stack>

                    <TaskNoteList task={task} onSend={handleSend} />
                  </Stack>
                </Box>
              </Stack>
            </TaskWrapper>
          ))}
      </Stack>

      <Menu
        anchorEl={reassignedMenuEl}
        open={openReassignedMenuMenu}
        onClose={handleCloseReassignedMenu}
      >
        {!taskAssignmentCandidates && <CircularProgress />}

        {taskAssignmentCandidates && (
          <MenuItem
            onClick={() => {
              void handleReassignMember(null)
            }}
          >
            <ListItemIcon>
              <Avatar
                alt={formatMessage({
                  id: 'task_edit_form.option.no_assignee',
                })}
                sx={{
                  width: 20,
                  height: 20,
                  fontSize: 14,
                }}
              />
            </ListItemIcon>
            <ListItemText>
              {formatMessage({
                id: 'task_edit_form.option.no_assignee',
              })}
            </ListItemText>
          </MenuItem>
        )}

        {taskAssignmentCandidates?.teamCandidates?.[0].memberCandidates.map(
          (member) => (
            <MenuItem
              key={member.memberId}
              selected={
                member.memberId ===
                selectedTask?.assignment?.assignmentSummary?.assigneeId
              }
              onClick={() => {
                void handleReassignMember(member.memberId)
              }}
            >
              <ListItemIcon>
                <Avatar
                  alt={member.name}
                  sx={{
                    width: 20,
                    height: 20,
                    fontSize: 14,
                  }}
                  src={getThumbnailUrl(member.avatarUrl)}
                >
                  {nameInitials(member.name)}
                </Avatar>
              </ListItemIcon>

              <ListItemText>{member.name}</ListItemText>
            </MenuItem>
          ),
        )}
      </Menu>

      <Menu anchorEl={menuEl} open={openMenu} onClose={handleCloseMenu}>
        {selectedTask?.status !== CaseTaskStatus.CANCELLED && (
          <MenuItem onClick={handleEditTask}>
            <ListItemIcon>
              <EditIcon color="primary" />
            </ListItemIcon>
            <ListItemText>
              {formatMessage({
                id: 'action_list.menu.edit',
              })}
            </ListItemText>
          </MenuItem>
        )}

        <MenuItem onClick={handleCopyTask}>
          <ListItemIcon>
            <ContentCopyIcon color="primary" />
          </ListItemIcon>
          <ListItemText>
            {formatMessage({
              id: 'action_list.menu.copy',
            })}
          </ListItemText>
        </MenuItem>
      </Menu>

      <Dialog open={isConfirmDialogOpen} maxWidth="xs">
        <DialogTitle textAlign="center">
          <Stack alignItems="center" spacing={2}>
            <ErrorIcon />
            <Box textAlign="center">
              {formatMessage({ id: 'action_list.cancel_confirm.title' })}
            </Box>
          </Stack>
        </DialogTitle>
        <DialogContent>
          <DialogContentText textAlign="center">
            {formatMessage({ id: 'action_list.cancel_confirm.content' })}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Stack spacing={1} width="100%">
            <Button
              onClick={handleConfirmedCancelTask}
              variant="contained"
              fullWidth
              color="error"
            >
              {formatMessage({
                id: 'action_list.cancel_confirm.button.cancel_action',
              })}
            </Button>
            <Button
              onClick={handleCloseConfirmDialog}
              variant="outlined"
              fullWidth
              color="error"
              autoFocus
            >
              {formatMessage({
                id: 'action_list.cancel_confirm.button.close',
              })}
            </Button>
          </Stack>
        </DialogActions>
      </Dialog>
    </Stack>
  )
}

export default TaskList
