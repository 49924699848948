import { useCallback, useEffect, useMemo, useState } from 'react'
import { useIntl } from 'react-intl'
import useSWR, { mutate } from 'swr'
import { useRecoilValue } from 'recoil'
import { useSnackbar } from 'notistack'
import styled from '@mui/material/styles/styled'
import {
  type GridColDef,
  type GridSortModel,
  useGridApiRef,
} from '@mui/x-data-grid'
import Stack from '@mui/material/Stack'
import CircularProgress from '@mui/material/CircularProgress'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import ListItemText from '@mui/material/ListItemText'
import IconButton from '@mui/material/IconButton'
import Tooltip from '@mui/material/Tooltip'
import LoadingButton from '@mui/lab/LoadingButton'
import Typography from '@mui/material/Typography'
import CardHeader from '@mui/material/CardHeader'
import Avatar from '@mui/material/Avatar'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import AddIcon from '@mui/icons-material/Add'
import InfoIcon from '@mui/icons-material/InfoOutlined'

import {
  DataTable,
  InfoText,
  MainHeader,
  SubHeader,
} from 'components/StyledComponents'
import { portalSettingState } from 'state/portalSettingStates'
import useApi from 'hooks/useApi'
import { type ItemBasic } from 'components/item/itemTypes'
import usePortalSetting from 'hooks/usePortalSetting'
import { FileType, Path } from 'commonConstants'
import ErrorIcon from 'assets/icons/error_icon.svg'
import { FeatureAccess, PortalSection } from 'components/role/roleConstants'
import useMember from 'hooks/useMember'
import useRoute from 'hooks/useNavigate'
import EmptyNewsIcon from 'assets/icons/empty_news.svg'
import { type News } from 'components/news/newsTypes'
import { NewsStatus } from 'components/news/newsConstants'
import ResourceWrapper from 'components/resource/ResourceWrapper'
import NewsPreviewDialog from 'components/news/NewsPreviewDialog'
import { convertLocalizedStringToData, nameInitials } from 'utils/stringUtils'
import { getThumbnailUrl } from 'utils/fileUtils'
import { EmptyImageIcon } from 'components/icons/Icons'

const Wrapper = styled(Stack)`
  border-radius: ${({ theme }) => 2 * theme.shape.borderRadius}px;
  border: 1px solid ${({ theme }) => theme.palette.divider};
  position: relative;
`

const ViewTabsWrapper = styled(Stack)`
  background: ${({ theme }) => theme.palette.info.light};
  border-radius: ${({ theme }) => 2 * theme.shape.borderRadius}px;

  & .MuiButton-endIcon .counter {
    padding: 0px 8px;
    border-radius: ${({ theme }) => theme.shape.borderRadius}px;
    background: ${({ theme }) => theme.palette.primary.light};
    font-size: 12px;
    color: ${({ theme }) => theme.palette.text.primary};
  }

  & .MuiButton-text {
    & .counter {
      background: ${({ theme }) => theme.palette.primary.light};
      color: ${({ theme }) => theme.palette.text.primary};
    }
  }
`

const NewsListPage: React.FC = () => {
  const { formatMessage } = useIntl()
  const { enqueueSnackbar } = useSnackbar()
  const dataTableRef = useGridApiRef()
  const { goTo } = useRoute()
  const { checkAccesses } = useMember()
  const { getLocalizedContent, formatDate } = usePortalSetting()
  const portalSetting = useRecoilValue(portalSettingState)
  const { sendDeleteRequest, sendPostRequest } = useApi()
  const [isSaving, setIsSaving] = useState(false)
  const [menuEl, setMenuEl] = useState<null | HTMLElement>(null)
  const openMenu = Boolean(menuEl)
  const [selectedNews, setSelectedNews] = useState<News | null>(null)
  const [isDeleteConfirmDialogOpen, setIsDeleteConfirmDialogOpen] =
    useState(false)
  const [currentView, setCurrentView] = useState<NewsStatus | 'logs'>(
    NewsStatus.PUBLISHED,
  )
  const [isPublishConfirmDialogOpen, setIsPublishConfirmDialogOpen] =
    useState(false)
  const [isArchiveConfirmDialogOpen, setIsArchiveConfirmDialogOpen] =
    useState(false)
  const [isPreviewDialogOpen, setIsPreviewDialogOpen] = useState(false)
  const [sortModel, setSortModel] = useState<GridSortModel>([])

  const {
    data: newsData,
    isLoading,
    mutate: mutateNews,
  } = useSWR<News[]>(
    portalSetting
      ? `${process.env.REACT_APP_API_PATH ?? ''}/portals/${
          portalSetting.id
        }/news?recordsNum=1000`
      : null,
  )

  useEffect(() => {
    setSortModel([
      {
        field: currentView === NewsStatus.PUBLISHED ? 'published' : 'updated',
        sort: 'desc',
      },
    ])
  }, [currentView])

  const handleOpenMenu = (
    event: React.MouseEvent<HTMLElement>,
    news: News,
  ): void => {
    setMenuEl(event.currentTarget)
    setSelectedNews(news)
  }

  const handleCloseMenu = (): void => {
    setMenuEl(null)
  }

  const columns = useMemo((): GridColDef[] => {
    if (!portalSetting) {
      return []
    }

    const columnsData: GridColDef[] = [
      {
        field: 'image',
        headerName: '',
        width: 100,
        disableColumnMenu: true,
        filterable: false,
        sortable: false,
        renderCell: (params) => {
          return params.row.url ? (
            <ResourceWrapper
              size={70}
              url={params.row.url}
              format={FileType.IMAGE}
              imageSize="cover"
            />
          ) : (
            <ResourceWrapper
              size={70}
              format={FileType.IMAGE}
              imageSize="cover"
              hasBorder={false}
            >
              <EmptyImageIcon sx={{ width: 60, height: 49 }} />
            </ResourceWrapper>
          )
        },
      },
      {
        field: 'title',
        headerName: formatMessage({ id: 'news_list.label.title' }),
        flex: 2,
        valueGetter: (value, row) => getLocalizedContent(row.titles),
        renderCell: (params) => getLocalizedContent(params.row.titles),
      },

      {
        field: 'items',
        headerName: formatMessage({ id: 'news_list.label.items' }),
        flex: 1,
        valueGetter: (value, row) =>
          row.items
            .map((item: ItemBasic) => getLocalizedContent(item.names))
            .join(', '),
        renderCell: (params) =>
          params.row.items
            .map((item: ItemBasic) => getLocalizedContent(item.names))
            .join(', '),
      },
    ]

    if (currentView !== NewsStatus.PUBLISHED) {
      columnsData.push(
        {
          field: 'author',
          headerName: formatMessage({
            id: 'news_list.label.author',
          }),
          flex: 2,
          valueGetter: (value, row) => row.author?.name,
          renderCell: (params) => (
            <CardHeader
              sx={{
                paddingLeft: 1,
                paddingRight: 1,
              }}
              avatar={
                <Avatar
                  src={getThumbnailUrl(params.row.author?.avatarUrl)}
                  sx={{ width: 30, height: 30, fontSize: 16 }}
                  alt={params.row.author?.name}
                >
                  {nameInitials(params.row.author?.name)}
                </Avatar>
              }
              title={params.row.author?.name}
              subheader={<InfoText>{params.row.author?.email}</InfoText>}
            />
          ),
        },
        {
          field: 'updated',
          headerName: formatMessage({
            id: 'news_list.label.updated_at',
          }),
          flex: 1,
          disableColumnMenu: true,
          renderCell: (params) => {
            const datetime = formatDate(params.value).split(' ')
            return (
              <Stack>
                <Typography variant="body1">{datetime[0]}</Typography>
                <InfoText>{datetime[1]}</InfoText>
              </Stack>
            )
          },
        },
      )
    }

    if (currentView === NewsStatus.PUBLISHED) {
      columnsData.push(
        {
          field: 'publisher',
          headerName: formatMessage({
            id: 'news_list.label.publisher',
          }),
          flex: 2,
          disableColumnMenu: true,
          valueGetter: (value, row) => row.publisher?.name,
          renderCell: (params) => (
            <CardHeader
              sx={{
                paddingLeft: 1,
                paddingRight: 1,
              }}
              avatar={
                <Avatar
                  src={getThumbnailUrl(params.row.publisher?.avatarUrl)}
                  sx={{ width: 30, height: 30, fontSize: 16 }}
                  alt={params.row.publisher?.name}
                >
                  {nameInitials(params.row.publisher?.name)}
                </Avatar>
              }
              title={params.row.publisher?.name}
              subheader={<InfoText>{params.row.publisher?.email}</InfoText>}
            />
          ),
        },
        {
          field: 'published',
          headerName: formatMessage({
            id: 'news_list.label.published_at',
          }),
          flex: 1,
          disableColumnMenu: true,
          filterable: false,
          sortable: true,
          renderCell: (params) => {
            const datetime = formatDate(params.value).split(' ')
            return (
              <Stack>
                <Typography variant="body1">{datetime[0]}</Typography>
                <InfoText>{datetime[1]}</InfoText>
              </Stack>
            )
          },
        },
      )
    }

    if (checkAccesses({ [PortalSection.NEWS_POSTS]: [FeatureAccess.WRITE] })) {
      columnsData.push({
        field: 'action',
        type: 'actions',
        getActions: ({ row }) => [
          <IconButton
            key={row.id}
            onClick={(event) => {
              handleOpenMenu(event, row)
            }}
            size="small"
            aria-label={formatMessage({
              id: 'general.icon_button.see_more',
            })}
            role="button"
          >
            <MoreVertIcon
              sx={{
                fontSize: 16,
              }}
            />
          </IconButton>,
        ],
      })
    }

    return columnsData
  }, [portalSetting, currentView])

  const handleOpenDeleteConfirmDialog = (): void => {
    setIsDeleteConfirmDialogOpen(true)
    handleCloseMenu()
  }

  const handleCloseDeleteConfirmDialog = (): void => {
    setIsDeleteConfirmDialogOpen(false)
  }

  const deleteNews = useCallback(async (): Promise<void> => {
    if (!portalSetting || !selectedNews) {
      return
    }

    try {
      setIsSaving(true)
      await sendDeleteRequest(
        `${process.env.REACT_APP_API_PATH ?? ''}/portals/${
          portalSetting?.id
        }/news/${selectedNews.id}`,
      )

      handleCloseDeleteConfirmDialog()
      enqueueSnackbar(formatMessage({ id: 'general.text.changes_saved' }), {
        variant: 'success',
      })

      await mutateNews()
    } catch (error) {
      console.error(error)
    } finally {
      setIsSaving(false)
    }
  }, [portalSetting, selectedNews])

  const handleArchiveNews = useCallback(async (): Promise<void> => {
    if (!portalSetting || !selectedNews) {
      return
    }

    try {
      setIsSaving(true)
      await sendPostRequest(
        `${process.env.REACT_APP_API_PATH ?? ''}/portals/${
          portalSetting?.id
        }/news/${selectedNews.id}:archive`,
      )

      handleCloseArchiveConfirmDialog()
      enqueueSnackbar(formatMessage({ id: 'general.text.changes_saved' }), {
        variant: 'success',
      })

      await mutateNews()
    } catch (error) {
      console.error(error)
    } finally {
      setIsSaving(false)
    }
  }, [portalSetting, selectedNews])

  const handlePublishNews = useCallback(async (): Promise<void> => {
    if (!portalSetting || !selectedNews) {
      return
    }

    try {
      setIsSaving(true)
      await sendPostRequest(
        `${process.env.REACT_APP_API_PATH ?? ''}/portals/${
          portalSetting?.id
        }/news/${selectedNews.id}:publish`,
      )

      handleClosePublishConfirmDialog()
      enqueueSnackbar(formatMessage({ id: 'general.text.changes_saved' }), {
        variant: 'success',
      })

      await mutateNews()
    } catch (error) {
      console.error(error)
    } finally {
      setIsSaving(false)
    }
  }, [portalSetting, selectedNews])

  const publishedNews = useMemo(
    () =>
      newsData?.filter((news) => news.status === NewsStatus.PUBLISHED) ?? [],
    [newsData],
  )

  const draftNews = useMemo(
    () => newsData?.filter((news) => news.status === NewsStatus.DRAFT) ?? [],
    [newsData],
  )

  const archivedNews = useMemo(
    () => newsData?.filter((news) => news.status === NewsStatus.ARCHIVED) ?? [],
    [newsData],
  )

  const currentNews = useMemo(
    () =>
      currentView === NewsStatus.PUBLISHED
        ? publishedNews
        : currentView === NewsStatus.DRAFT
        ? draftNews
        : currentView === NewsStatus.ARCHIVED
        ? archivedNews
        : newsData ?? [],
    [currentView, publishedNews, draftNews, archivedNews, newsData],
  )

  const handleClosePublishConfirmDialog = (): void => {
    setIsPublishConfirmDialogOpen(false)
    setSelectedNews(null)
  }

  const handleOpenPublishConfirmDialog = (): void => {
    handleCloseMenu()
    setIsPublishConfirmDialogOpen(true)
  }

  const handleOpenArchiveConfirmDialog = (): void => {
    handleCloseMenu()
    setIsArchiveConfirmDialogOpen(true)
  }

  const handleCloseArchiveConfirmDialog = (): void => {
    setIsArchiveConfirmDialogOpen(false)
    setSelectedNews(null)
  }

  const handleEditNews = useCallback((): void => {
    if (selectedNews && portalSetting) {
      void mutate(
        `${process.env.REACT_APP_API_PATH ?? ''}/portals/${
          portalSetting.id
        }/templates/comments/${selectedNews.id}`,
        selectedNews,
        false,
      )

      goTo(`${Path.NEWS_EDIT}/${selectedNews.id}`)
    }
  }, [portalSetting, selectedNews])

  const handleCopy = useCallback((): void => {
    if (selectedNews && portalSetting) {
      void mutate(
        `${process.env.REACT_APP_API_PATH ?? ''}/portals/${
          portalSetting.id
        }/templates/comments/${selectedNews.id}`,
        selectedNews,
        false,
      )

      goTo(`${Path.NEWS_EDIT}/${selectedNews?.id}?isCopy=true`)
    }
  }, [portalSetting, selectedNews])

  const handleClosePreviousDialog = (): void => {
    setIsPreviewDialogOpen(false)
  }

  const handleRowClick = useCallback((row: News): void => {
    setSelectedNews(row)
    setIsPreviewDialogOpen(true)
  }, [])

  const handleSortModelChange = (newSortModel: GridSortModel): void => {
    setSortModel(newSortModel)
  }

  return (
    <Stack height="100%" width="100%" spacing={2} overflow="auto">
      <Stack direction="row" width="100%" spacing={2}>
        <Box flexGrow={1}>
          <MainHeader>
            {formatMessage({ id: 'news_list.header' })}
            <Tooltip
              title={formatMessage({
                id: 'news_list.label.creating_news_help_text',
              })}
            >
              <InfoIcon fontSize="small" />
            </Tooltip>
          </MainHeader>
        </Box>

        {newsData &&
          newsData?.length > 0 &&
          checkAccesses({
            [PortalSection.NEWS_POSTS]: [FeatureAccess.WRITE],
          }) && (
            <Button
              variant="contained"
              size="small"
              startIcon={<AddIcon />}
              onClick={() => {
                goTo(Path.NEWS_ADD)
              }}
              color="secondary"
            >
              {formatMessage({
                id: 'news_list.button.create_news',
              })}
            </Button>
          )}
      </Stack>

      <ViewTabsWrapper direction="row" padding={1} spacing={1}>
        <Button
          size="small"
          variant={currentView === NewsStatus.PUBLISHED ? 'contained' : 'text'}
          onClick={() => {
            setCurrentView(NewsStatus.PUBLISHED)
          }}
          endIcon={
            publishedNews.length > 0 && (
              <Box className="counter">{publishedNews.length}</Box>
            )
          }
        >
          {formatMessage({ id: 'news_list.view.published' })}
        </Button>
        <Button
          size="small"
          variant={currentView === NewsStatus.DRAFT ? 'contained' : 'text'}
          onClick={() => {
            setCurrentView(NewsStatus.DRAFT)
          }}
          endIcon={
            draftNews.length > 0 && (
              <Box className="counter">{draftNews.length}</Box>
            )
          }
        >
          {formatMessage({ id: 'news_list.view.draft' })}
        </Button>
        <Button
          size="small"
          variant={currentView === NewsStatus.ARCHIVED ? 'contained' : 'text'}
          onClick={() => {
            setCurrentView(NewsStatus.ARCHIVED)
          }}
          endIcon={
            archivedNews.length > 0 && (
              <Box className="counter">{archivedNews.length}</Box>
            )
          }
        >
          {formatMessage({ id: 'news_list.view.archived' })}
        </Button>
      </ViewTabsWrapper>

      <Stack flexGrow={1} overflow="auto" spacing={2}>
        {newsData &&
          newsData.length === 0 &&
          checkAccesses({
            [PortalSection.NEWS_POSTS]: [FeatureAccess.WRITE],
          }) && (
            <Wrapper
              width="100%"
              height="100%"
              alignItems="center"
              justifyContent="center"
            >
              <Stack textAlign="center" spacing={2} alignItems="center">
                <EmptyNewsIcon />
                <Box width="60%">
                  <SubHeader>
                    {formatMessage({
                      id: 'news_list.label.start_creating_news',
                    })}
                  </SubHeader>
                  <Typography variant="body2">
                    {formatMessage({
                      id: 'news_list.label.creating_news_help_text',
                    })}
                  </Typography>
                </Box>
                <Box width="100%">
                  <Button
                    variant="contained"
                    size="small"
                    startIcon={<AddIcon />}
                    onClick={() => {
                      goTo(Path.NEWS_ADD)
                    }}
                    color="secondary"
                  >
                    {formatMessage({
                      id: 'news_list.button.create_news',
                    })}
                  </Button>
                </Box>
              </Stack>
            </Wrapper>
          )}

        {isLoading && <CircularProgress />}

        {!!newsData?.length && !isLoading && (
          <DataTable
            apiRef={dataTableRef}
            rows={currentNews}
            columns={columns}
            sortModel={sortModel}
            getRowHeight={() => 'auto'}
            onRowClick={(param) => {
              handleRowClick(param.row)
            }}
            onSortModelChange={handleSortModelChange}
            hideFooter
            slotProps={{
              row: {
                tabIndex: 0,
                onKeyDown: (event) => {
                  if (event.key === 'Enter') {
                    const row = event.target as HTMLTableRowElement
                    row.click()
                  }
                },
              },
            }}
          />
        )}
      </Stack>

      <Menu
        anchorEl={menuEl}
        open={openMenu}
        onClose={handleCloseMenu}
        role="menu"
      >
        {selectedNews?._operations.canEdit && (
          <MenuItem onClick={handleEditNews}>
            <ListItemText>
              {formatMessage({
                id: 'general.icon_button.edit',
              })}
            </ListItemText>
          </MenuItem>
        )}

        {selectedNews?._operations.canArchive && (
          <MenuItem onClick={handleOpenArchiveConfirmDialog}>
            <ListItemText>
              {formatMessage({
                id: 'news_list.menu.archive',
              })}
            </ListItemText>
          </MenuItem>
        )}

        {selectedNews?._operations.canRemove && (
          <MenuItem onClick={handleOpenDeleteConfirmDialog}>
            <ListItemText>
              {formatMessage({
                id: 'general.icon_button.delete',
              })}
            </ListItemText>
          </MenuItem>
        )}

        {selectedNews?._operations.canPublish && (
          <MenuItem onClick={handleOpenPublishConfirmDialog}>
            <ListItemText>
              {formatMessage({
                id: 'news_list.menu.publish',
              })}
            </ListItemText>
          </MenuItem>
        )}

        {selectedNews?._operations.canCopy && (
          <MenuItem onClick={handleCopy}>
            <ListItemText>
              {formatMessage({
                id: 'news_list.menu.copy_to_draft',
              })}
            </ListItemText>
          </MenuItem>
        )}
      </Menu>

      <Dialog open={isDeleteConfirmDialogOpen} maxWidth="sm">
        <DialogTitle textAlign="center">
          <Stack alignItems="center" spacing={2}>
            <ErrorIcon />
            <Box textAlign="center">
              {formatMessage({
                id: 'news_list.confirm.delete.title',
              })}
            </Box>
          </Stack>
        </DialogTitle>
        <DialogContent sx={{ textAlign: 'center' }}>
          {formatMessage({ id: 'news_list.confirm.delete.content' })}
        </DialogContent>
        <DialogActions>
          <Stack width="100%" spacing={1}>
            <LoadingButton
              loading={isSaving}
              fullWidth
              onClick={(): void => {
                void deleteNews()
              }}
              autoFocus
              variant="contained"
              color="secondary"
            >
              {formatMessage({
                id: 'general.icon_button.delete',
              })}
            </LoadingButton>
            <Button
              fullWidth
              onClick={handleCloseDeleteConfirmDialog}
              variant="outlined"
              color="secondary"
            >
              {formatMessage({
                id: 'general.button.cancel',
              })}
            </Button>
          </Stack>
        </DialogActions>
      </Dialog>

      <Dialog open={isPublishConfirmDialogOpen} maxWidth="xs">
        <DialogTitle sx={{ textAlign: 'center' }}>
          {formatMessage({ id: 'news_edit.confirm.publish.title' })}
        </DialogTitle>
        <DialogContent sx={{ textAlign: 'center' }}>
          {formatMessage({ id: 'news_edit.confirm.publish.content' })}
        </DialogContent>
        <DialogActions>
          <Stack width="100%" spacing={1}>
            <LoadingButton
              loading={isSaving}
              fullWidth
              onClick={() => {
                void handlePublishNews()
              }}
              autoFocus
              variant="contained"
              color="error"
            >
              {formatMessage({
                id: 'news_edit.button.publish',
              })}
            </LoadingButton>
            <Button
              fullWidth
              onClick={handleClosePublishConfirmDialog}
              variant="outlined"
              color="error"
            >
              {formatMessage({
                id: 'general.button.cancel',
              })}
            </Button>
          </Stack>
        </DialogActions>
      </Dialog>

      <Dialog open={isArchiveConfirmDialogOpen} maxWidth="xs">
        <DialogTitle sx={{ textAlign: 'center' }}>
          {formatMessage({ id: 'news_list.confirm.archive.title' })}
        </DialogTitle>
        <DialogContent sx={{ textAlign: 'center' }}>
          {formatMessage({ id: 'news_list.confirm.archive.content' })}
        </DialogContent>
        <DialogActions>
          <Stack width="100%" spacing={1}>
            <LoadingButton
              loading={isSaving}
              fullWidth
              onClick={() => {
                void handleArchiveNews()
              }}
              autoFocus
              variant="contained"
              color="error"
            >
              {formatMessage({
                id: 'news_edit.button.archive',
              })}
            </LoadingButton>
            <Button
              fullWidth
              onClick={handleCloseArchiveConfirmDialog}
              variant="outlined"
              color="error"
            >
              {formatMessage({
                id: 'general.button.cancel',
              })}
            </Button>
          </Stack>
        </DialogActions>
      </Dialog>

      <NewsPreviewDialog
        isOpen={isPreviewDialogOpen}
        onClose={handleClosePreviousDialog}
        imageUrl={selectedNews?.url}
        titles={convertLocalizedStringToData(selectedNews?.titles)}
        bodies={convertLocalizedStringToData(selectedNews?.bodies)}
        published={selectedNews?.published}
      />
    </Stack>
  )
}

export default NewsListPage
