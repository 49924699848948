import { useIntl } from 'react-intl'
import { TreeItem } from '@mui/x-tree-view/TreeItem'
import Button from '@mui/material/Button'
import AddIcon from '@mui/icons-material/Add'

import { type CategoryRawData } from 'components/category/categoryTypes'
import CategoryEditItem from './CategoryEditItem'
import { CATEGORY_MAX_DEPTH } from 'commonConstants'

type SubCategoryTreeProps = {
  paths: string[]
  categories: CategoryRawData[]
  onAddCategory: (paths: string[]) => void
  onDeleteCategory: (paths: string[]) => void
  onEditCategory: (
    paths: string[],
    translations: Record<string, string>,
  ) => void
  currentHoverItem: string | null
  setCurrentHoverItem: (currentHoverItem: string | null) => void
  onMoveCategory: (paths: string[], index: number, toIndex: number) => void
}

const SubCategoryEditTree: React.FC<SubCategoryTreeProps> = ({
  paths,
  categories,
  onAddCategory,
  onDeleteCategory,
  onEditCategory,
  currentHoverItem,
  setCurrentHoverItem,
  onMoveCategory,
}) => {
  const { formatMessage } = useIntl()

  if (paths.length >= CATEGORY_MAX_DEPTH) {
    return null
  }

  return (
    <>
      {categories?.map((category, index) => (
        <CategoryEditItem
          key={category.id}
          index={index}
          paths={[...paths, category.id]}
          category={category}
          onAddCategory={onAddCategory}
          onDeleteCategory={onDeleteCategory}
          onEditCategory={onEditCategory}
          currentHoverItem={currentHoverItem}
          setCurrentHoverItem={setCurrentHoverItem}
          onMoveCategory={onMoveCategory}
        />
      ))}

      {paths.length < CATEGORY_MAX_DEPTH && (
        <TreeItem
          itemId={`${paths.join('|')}|add`}
          label={
            <Button
              variant="outlined"
              startIcon={<AddIcon />}
              size="small"
              onClick={(event) => {
                event.stopPropagation()
                onAddCategory(paths)
              }}
            >
              {formatMessage({ id: 'edit_categories.button.add_new' })}
            </Button>
          }
        ></TreeItem>
      )}
    </>
  )
}

export default SubCategoryEditTree
